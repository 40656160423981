import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, Link, unstable_HistoryRouter } from 'react-router-dom'


const Login = () => {
  
// const [showPassword, setShowPassword] = useState(false);
// const toggleShowPassword = () => setShowPassword(!showPassword);
// const [isHidden, setIsHidden] = useState(true);
  // Constants:
  // const navigate = useNavigate()
  // const dispatch = useDispatch()

  // State:
  const [ isLoading, setIsLoading ] = useState(false)
  const [ username, setUsername ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ errorText, setErrorText ] = useState('')

  

  // Functions:
  // const validate = () => {
  //   setErrorText('')
  //   if (username.length === 0) { setErrorText('Invalid email'); return false }
  //   if (password.length === 0) { setErrorText('Invalid password'); return false }
  //   return true
  // }
  // const refresh = () => window.location.reload(true)

  // const handleLogin = async () => {
    // if (isLoading) return
    // setIsLoading(true)
    // const validationResult = validate()
    // if (!validationResult) { setIsLoading(false); return }
    // const { result, payload } = await login({ username, password }, data => dispatch(_login(data)))
    // setIsLoading(false)
    // if (!result) setErrorText('Invalid credentials.') 
    // else{
    //    navigate(ROUTES.INDEX)
    //    window.location.href = "/"
    //   //  refresh();
    //   }
  // }

  // Effects:
  // useEffect(() => {
  //   if (
  //     localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN) &&
  //     !hasTokenExpired(localStorage.getItem(LOCAL_STORAGE_KEYS.EXPIRATION))
  //   ) {
  //     navigate(ROUTES.HOME)
  //   }
  // }, [ navigate ])

 // Return:
 return (

  <Wrapper>
   
   <LoginContainer>
    {/*  <Typography sx={{ typography: { md: 'h4', xs: 'h4' },fontWeight: 'bold'  }} className='text-center mb-4'>
      Log In
     </Typography> */}
     <FormField onSubmit={
       e => {
         e.preventDefault()
       //  handleLogin()
       }
     }>
          <div className='mb-3' style={{width:'300px' }}  >
          <label className='form-label'>Email</label>
          <input
          style={{border:'1px solid #002857', width:'275px', marginLeft:'0'}}
            type='text'
            className='form-control'
            id='username'
            placeholder='Email'
            // value={ username }
            // onChange={ e => setUsername(e.currentTarget.value) }
          />
        </div>
        <div className='mb-3' style={{position:'relative', width:'300px', height:'100px' }}>
          <label className='form-label'>Password</label>
          <input
           style={{border:'1px solid #002857', borderRadius:'0', width:'280px', height:'33.33px',  marginLeft:'0'}}
      //     type={showPassword ? 'text' : 'password'}
            className='form-control'
            id='password'
            placeholder='Password'
            // value= {password}
            // onChange={ e => setPassword(e.currentTarget.value) }
          />
             {/* {isHidden ? password.replace(/./g, '*') : password}  */}
             {/* <HideShowButton onClick={toggleShowPassword}> 
            {showPassword ? <AiOutlineEye/>:  <AiOutlineEyeInvisible/>     }
          </HideShowButton> */}
          
         {/*  <Typography variant='body2' >
          <Link to={ "" } style={{marginLeft:'0rem'}} >Forgot Password?</Link>
        </Typography> */}
        </div>
        </FormField>
    </LoginContainer>
  </Wrapper>

)
}


// Exports:
export default Login

const HideShowButton = styled.span`
position: absolute;
border: none;
right: 9%;
top: 33%;
background-color: white;
`


const Wrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;  
width: 100%;
height: 100vh;
background-color: #f0f5f7;
`

const LoginContainer = styled.div`
height: 350px;
width: 40%;
min-width: 350px;
background-color: white;
padding: 2%;
box-shadow: 0px 1px 1px 1px rgba(0,0,0,.4);
border-radius: 3px;

@media only screen and (max-width: 381px)  {
  width: 90%;
  margin:  1rem;
}

`

const ErrorText = styled.div`

padding-bottom: 0.5rem;
font-weight: 600;
color: #841B1B;
`
const FormField = styled.form`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`


