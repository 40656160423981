import React from 'react'
import Footer from '../footer/Footer'
import { styled } from 'styled-components'

const Awards = () => {
  return (
    <div>
      <Bannerdiv>
        <BannerImg src="https://lh3.googleusercontent.com/p/AF1QipM9css2bqscCcH8pCsqWdW98Y1BUVsS_41N9yD1=s680-w680-h510" alt="banner"/>
{/*         <BannerHeadingP> Awards & Achievement </BannerHeadingP> */}
      </Bannerdiv>
      
      <CareerContainer>
          <CareerHeading> Awards and Achievement  </CareerHeading>
          <CareerContent>
            The managing director (MD) Dr. Manoj Kumar is a famous name in Darbhanga and North Bihar. 

          </CareerContent>
          <EachCareerDiv>
            {/* <DetailsDiv > 
              <summery>Click me</summery>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga aliquam exercitationem molestiae vel, aut accusantium incidunt dolorum cumque ad alias aperiam labore repudiandae deleniti voluptatum ratione natus itaque! Voluptatem aliquam magni quisquam quam quas veritatis esse dicta suscipit distinctio, velit laboriosam cupiditate debitis, aspernatur tenetur modi provident dolor corrupti beatae.</p>
            </DetailsDiv> */}

          </EachCareerDiv>
        </CareerContainer>     
          <Footer />
    </div>
  )
}

export default Awards


const Bannerdiv = styled.div`
position: relative;

`
const BannerImg = styled.img`
  width: 100vw;
  loading:lazy;
  height: auto;
`

const BannerHeadingP = styled.p`
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const DetailsDiv = styled.details`
  border: 1px solid red;
  color: #050572;
  font-size: 25px;
  margin-top: 2rem;
  padding: 8px 10px 10px 10px;
  
`
const CareerHeading = styled.h1`
font-size: 40px;
font-weight: 700;
color: #c81313;
text-transform: uppercase;
letter-spacing: 1px;
word-spacing: 20px;
margin-top: 1rem;
@media screen and (min-width:200px) and (max-width: 650px){
  font-size: 25px;
  font-weight: 700;
  letter-spacing: auto;
  word-spacing: auto;
}
`
const CareerContent= styled.div`
font-size: 22px;
font-weight: 500;
opacity: 0.9;
@media screen and (min-width:200px) and (max-width: 650px){
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
}
`

const EachCareerDiv = styled.div`

`

const CareerContainer = styled.div`
padding: 2.5rem 4%;
`