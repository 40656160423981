import React from 'react'
import Footer from '../footer/Footer'
import { styled } from 'styled-components'
import Aos from 'aos';
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';


const Emergency = () => {
  return (
    <div>
      <Bannerdiv>
        
        <BannerImg src="https://urostonedarbhanga.com/images/Emergency_page_banner.png" alt="banner"/>
        {/* <BannerHeadingP> Emergency </BannerHeadingP> */}
      </Bannerdiv>
        <EmergencyContent>
        <Emergencyh2>Emergency Services</Emergencyh2>
            <Declaration> 
              A Team of Expert Doctors and Nursing Staffs are available all the time to take care of coming Emergency Patients. 
              We are having daily Emergency patients coming from Nepal and all North Bihar Region (Sitamarhi, Darbhanga, Samastipur, Muzaffarpur, Madhubani, Supaul, Jankpur and near by places).
            </Declaration>
            <SubHeading1>
             Emergency Services For Following Urology Conditions Available Here
            </SubHeading1>

            <EmergencyEachDiv  data-aos="fade-up">
              <div style={{display:"flex", flexDirection:"column"}}>
              <SubHeading1 style={{width:"400px", fontSize:"23px", marginTop:"2rem"}}>
                Urinary Retention (मूत्रीय अवरोधन)
              </SubHeading1>
              <span style={{height:"1px", width: "100%", background:"#c81313", marginTop:"0"}}></span>
              </div>
              <EmergencyContentDetails data-aos="fade-right">
                  <EmergencyImg src="https://www.uromed.eu/layout/img/inhalt/restharn-en.jpg" alt="Urinary Retention"/>

                  <p>Urinary retention is a condition where your bladder doesn’t empty all the way or at all when you urinate. Your bladder is like a storage tank for urine.
                    <br/>
                    When you have urinary retention, it can be acute (sudden) or chronic (long-term). Acute means that it comes on quickly and it could be severe. Chronic urinary retention means that you’ve had the condition for a longer period of time.
                    <br/>
                    The acute form of urinary retention is an emergency. In this case, you’ll need to see a healthcare provider right away. The chronic form happens most of the time in older men, but it can also occur in women.
                    <br/>
                    <br/>
                    मूत्रीय अवरोधन एक ऐसी स्थिति है जहां जब आप पेशाब करते हैं तो आपका मूत्राशय पूरी तरह से या बिल्कुल भी खाली नहीं होता है। आपका मूत्राशय मूत्र के भंडारण टैंक की तरह है।
                    <br/>
                    जब आपको मूत्रीय अवरोधन होता है, तो यह तीव्र (अचानक) या क्रोनिक (दीर्घकालिक) हो सकता है। तीव्र का मतलब है कि यह तेजी से आता है और यह गंभीर भी हो सकता है। क्रोनिक मूत्र प्रतिधारण का मतलब है कि आपको यह समस्या लंबे समय से है
                    <br/>
                    मूत्रीय अवरोधन का तीव्र रूप एक आपातकालीन स्थिति है। इस मामले में, आपको तुरंत एक स्वास्थ्य सेवा प्रदाता से मिलना होगा। क्रोनिक रूप ज्यादातर वृद्ध पुरुषों में होता है, लेकिन यह महिलाओं में भी हो सकता है।
                  </p>

              </EmergencyContentDetails>
              <EmergencyButton> 
                  <HyperLink to="/appointment">
                    Emergency Appointment
                  </HyperLink> 
              </EmergencyButton>
            </EmergencyEachDiv>

            <EmergencyEachDiv data-aos="flip-left">
              <div style={{display:"flex", flexDirection:"column"}}>
              <SubHeading1 style={{width:"400px", fontSize:"23px", marginTop:"2rem"}}>
              Bleeding per Urethra (Hematuria) (मूत्रमार्ग से रक्तस्राव (हेमट्यूरिया))
              </SubHeading1>
              <span style={{height:"1px", width: "100%", background:"#c81313", marginTop:"0"}}></span>
              </div>
              <EmergencyContentDetails  >
                  <EmergencyImg src="https://asui.org/wp-content/uploads/2021/05/hematuria_1.jpg" alt="Urinary Retention"/>

                  <p>This condition happens when the kidneys or other parts of the urinary tract let blood cells leak into urine. Different problems can cause this leaking to happen, including:
                    
                  Urinary tract infections (UTIs), Kidney infection, A bladder or kidney stone, Enlarged prostate, Kidney disease, Cancer,Kidney injury,Hard exercise. 
                    If you she blood shade in your urine, you should contact to capable hospital like us.<br/>
                   <br/>
                    यह स्थिति तब होती है जब गुर्दे या मूत्र पथ के अन्य हिस्से रक्त कोशिकाओं को मूत्र में रिसने देते हैं। विभिन्न समस्याएँ इस रिसाव का कारण बन सकती हैं, जिनमें शामिल हैं:
                    
                    मूत्र पथ में संक्रमण (यूटीआई), गुर्दे में संक्रमण, मूत्राशय या गुर्दे में पथरी, बढ़ा हुआ प्रोस्टेट, गुर्दे की बीमारी, कैंसर, गुर्दे की चोट, कठिन व्यायाम।
                      यदि आपके पेशाब में खून आता है, तो आपको हमारे जैसे सक्षम अस्पताल से संपर्क करना चाहिए।
                    </p>

              </EmergencyContentDetails >
              <EmergencyButton> 
                  <HyperLink to="/appointment">
                    Emergency Appointment
                  </HyperLink> 
               </EmergencyButton>
            </EmergencyEachDiv>

            <EmergencyEachDiv data-aos="flip-right">
              <div style={{display:"flex", flexDirection:"column"}}>
              <SubHeading1 style={{width:"400px", fontSize:"23px", marginTop:"2rem"}}>
              Perforation: Intestinal Obstruction (आंत्र रुकावट)
              </SubHeading1>
              <span style={{height:"1px", width: "100%", background:"#c81313", marginTop:"0"}}></span>
              </div>
              <EmergencyContentDetails >
                  <EmergencyImg src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlo8Tc-XI2IFJliMSHiluWpTE46z7hjNltQg&usqp=CAU" alt="Urinary Retention"/>

                  <p>Intestinal perforation is a potentially devastating complication that may result from a variety of disease processes. Common causes of perforation include trauma, instrumentation, inflammation, infection, malignancy, ischemia, and obstruction.   
                    If you have following health conditions, contact an Expert Doctor:
                   
                   <ul>
                    <li>Abdominal pain or cramping, which is usually severe.</li>
                    <li>Bloating or a swollen abdomen.</li>
                    <li>Nausea and vomiting.</li>
                    <li>Pain or tenderness when you touch your abdomen.</li>
                    </ul> 

                    आंत्र वेध एक संभावित विनाशकारी जटिलता है जो विभिन्न प्रकार की रोग प्रक्रियाओं के परिणामस्वरूप हो सकती है। वेध के सामान्य कारणों में आघात, उपकरण, सूजन, संक्रमण, दुर्दमता, इस्किमिया और रुकावट शामिल हैं। यदि आपकी निम्नलिखित स्वास्थ्य स्थितियाँ हैं, तो किसी विशेषज्ञ डॉक्टर से संपर्क करें:
                    <ul>
                      <li>पेट में दर्द या ऐंठन, जो आमतौर पर गंभीर होती है।</li>
                      <li>पेट फूलना या फूला हुआ पेट।</li>
                      <li>Nausea और उल्टी।</li>
                      <li>जब आप अपने पेट को छूते हैं तो दर्द या कोमलता।</li>
                    </ul>
                  </p>

              </EmergencyContentDetails>
              <EmergencyButton> 
                  <HyperLink to="/appointment">
                    Emergency Appointment
                  </HyperLink> 
              </EmergencyButton>
            </EmergencyEachDiv>

            <EmergencyEachDiv data-aos="zoom-left">
              <div style={{display:"flex", flexDirection:"column", }}>
              <SubHeading1 style={{width:"400px", fontSize:"23px", marginTop:"2rem"}}>
              Urine Dribbling from Vegina (योनि से मूत्र का टपकना)
              </SubHeading1>
              <span style={{height:"1px", width: "100%", background:"#c81313", marginTop:"0"}}></span>
              </div>
              <EmergencyContentDetails >
                  <EmergencyImg src="https://www.rghospitals.com/assets/media/800x400/Vesicovaginal-Fistula_1.jpg" alt="Urinary Retention"/>

                  <p>
                    In this condition, a continuous leak of urine and wastes comes out of Vegina. Which can be caused by a many health conitions but most common is Operation during Childbirth and Physical  accedent. 
                    If You are having following health conditions, immediately contact to a Expert Doctor:-
                    <ul>
                      <li>Continuous Leak of urine, gas or stool from vegina</li>
                      <li>Bad Smell from urine</li>
                      <li>Infection in Urethra or Vegina </li>
                    </ul>   
                      
                    इस स्थिति में वेजिना से लगातार मूत्र और अपशिष्ट पदार्थ का रिसाव होता रहता है। यह कई स्वास्थ्य स्थितियों के कारण हो सकता है लेकिन सबसे आम है बच्चे के जन्म के दौरान ऑपरेशन और शारीरिक दुर्घटना। यदि आपको निम्नलिखित स्वास्थ्य समस्याएं हैं, तो तुरंत विशेषज्ञ डॉक्टर से संपर्क करें:-
                    <ul>
                      <li>	योनि से गैस, मल या मावाद बहना</li>
                      <li>	योनि से दुर्गंध आना</li>
                      <li>	बार-बार योनि या मूत्र पथ में संक्रमण  </li>
                    </ul> 
                  </p>

              </EmergencyContentDetails>
              <EmergencyButton> 
                  <HyperLink to="/appointment">
                    Emergency Appointment
                  </HyperLink> 
               </EmergencyButton>
            </EmergencyEachDiv>
          
        </EmergencyContent>
       <Footer/>
    </div>
  )
}

export default Emergency

const HyperLink = styled(Link)`
  text-decoration: none;
  color:#c81313;
  &:hover {
    color: #fff;
  }
`

const EmergencyButton = styled.button`
align-self: center;
margin-top: 0.5rem;
font-size: 20px;
font-weight: 500;
color:#c81313;
border: 1px solid #c81313;
padding: 5px 20px 7px 20px;
border-radius: 2px;
text-decoration: none;
background-color: white;
&:hover{
    background-color: #c81313;
    color: white;
    transition: all 0.5s ease-in-out;
}
`

const  EmergencyContentDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: flex-start;
  @media screen and (min-width:200px) and (max-width: 650px){
    flex-direction: column;
  }
`

const EmergencyImg = styled.img`
width: 40%;
height: auto;
@media screen and (min-width:200px) and (max-width: 650px){
  width: 80%;
height: auto;
display: flex;
align-items: center;
}

`

const EmergencyEachDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const SubHeading1 = styled.h2`
  font-size: 30px;
  font-wight: 600;
  color: #fff;
  padding: 2px 15px 5px 15px; 
  margin-top: 1rem;
  margin-bottom: 0;
  background-color:#c81313;
  @media screen and (min-width:200px) and (max-width: 650px){
    font-size: 22px;
    font-weight: 700;
    letter-spacing: auto;
    word-spacing: auto;
  }
`

const Emergencyh2 = styled.h2`
  font-size: 40px;
  font-weight: 700;
  color: #c81313;
  text-transform: uppercase;
  letter-spacing: 1px;
  word-spacing: 20px;
  @media screen and (min-width:200px) and (max-width: 650px){
    font-size: 25px;
    font-weight: 700;
    letter-spacing: auto;
    word-spacing: auto;
  }
`

const Declaration = styled.div`
  font-size: 22px;
  font-weight: 500;
  opacity: 0.9;
  @media screen and (min-width:200px) and (max-width: 650px){
    font-size: 16px;
    font-weight: 500;
    opacity: 0.9;
  }
`

const EmergencyContent = styled.div`
  width: 100vw;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const Bannerdiv = styled.div`
position: relative;

`
const BannerImg = styled.img`
  width: 100vw;
  height: auto;
  loading:lazy;
`

const BannerHeadingP = styled.p`
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`