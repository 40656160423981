import React from "react";
import TopHeader from "../pages/TopHeader";
import Navbar from "../pages/Navbar";
import Footer from "../footer/Footer";
import styled from 'styled-components';
import { FcDownRight } from "react-icons/fc";
import CustomizedTables from "./CustomizedTables";

const Services = () => {
  return (
    <UrologyWrapper>
      <Bannerdiv>
        <BannerImg src="https://urostonedarbhanga.com/images/urolog_page_banner.jpg" alt="banner"/>
{/*         <BannerHeadingP> Urology </BannerHeadingP> */}
      </Bannerdiv>
      <UrologyDetails>
        <Introduction>
          <IntroHeading>
                Introduction
          </IntroHeading>
          <span style={{background:"#c81313", height:"1px", width:"100%"}}></span>
          <IntroductionP>
          Urology is a part of health care that deals with diseases of the male and female urinary tract (kidneys, ureters, bladder and urethra). It also deals with the male organs that are able to make babies (penis, testes, scrotum, prostate, etc.). Since health problems in these body parts can happen to everyone, urologic health is important.            <br/>
          Urology is known as a surgical specialty. Besides surgery, a urologist is a doctor with wisdom of internal medicine, pediatrics, gynecology and other parts of health care. This is because a urologist encounters a wide range of clinical problems. The scope of urology is big and the American Urological Association has named seven subspecialty parts:
          
        </IntroductionP>

          <ul>
            <li>Pediatric Urology (children's urology)</li>
            <li>Urologic Oncology (urologic cancers)</li>
            <li>Renal (kidney) Transplant</li>
            <li>Male Infertility</li>
            <li>Calculi (urinary tract stones)</li>
            <li>Female Urology</li>
            <li>Neurourology (nervous system control of genitourinary organs)</li>
          </ul>

          <IntroHeading style={{width:"350px" }}>
          Common Urology OPD Procedures
          </IntroHeading>
          <span style={{background:"#c81313", height:"1px", width:"100%"}}></span>
            <IntroductionUl>
              <IntroductionLi><FcDownRight/> Catheterization</IntroductionLi>
              <IntroductionLi><FcDownRight/> Trocar Suprapubic catheterization (Trocar SPC)</IntroductionLi>
              <IntroductionLi><FcDownRight/>  Urodynamic study</IntroductionLi>
              <IntroductionLi><FcDownRight/>  Uroflowmetry</IntroductionLi>
            </IntroductionUl>
          
          <IntroHeading style={{width:"220px" }}>
            Day Care Procedures
          </IntroHeading>
          <span style={{background:"#c81313", height:"1px", width:"100%"}}></span>
            
          <IntroductionUl>
              <IntroductionLi><FcDownRight/> Dialysis</IntroductionLi>
              <IntroductionLi><FcDownRight/> Prostatic Biopsy</IntroductionLi>
              <IntroductionLi><FcDownRight/> Urethral Dilatation</IntroductionLi>
              <IntroductionLi><FcDownRight/>  Cystoscopy</IntroductionLi>
              <IntroductionLi><FcDownRight/>  Circumcision</IntroductionLi>
              <IntroductionLi><FcDownRight/>  Frenuloplasty</IntroductionLi>
              <IntroductionLi><FcDownRight/>  ESWL (Lithotripsy)</IntroductionLi>
              <IntroductionLi><FcDownRight/> Bilateral orchidectomy</IntroductionLi>
              <IntroductionLi><FcDownRight/>  DJ Stent removal</IntroductionLi>
            </IntroductionUl>


          <IntroHeading style={{width:"380px" }}>
            Common Endocrological Procedures
          </IntroHeading>
          <span style={{background:"#c81313", height:"1px", width:"100%"}}></span>
          <IntroductionUl>
              <IntroductionLi><FcDownRight/> URS</IntroductionLi>
              <IntroductionLi><FcDownRight/> PCNL</IntroductionLi>
              <IntroductionLi><FcDownRight/> TURP / Laser Prostatectomy (HOLEP)</IntroductionLi>
              <IntroductionLi><FcDownRight/>  TURBT</IntroductionLi>
              <IntroductionLi><FcDownRight/>  OIU</IntroductionLi>
              <IntroductionLi><FcDownRight/>  Laser Endopyelotomy</IntroductionLi>
              <IntroductionLi><FcDownRight/>  Cystolithotripsy</IntroductionLi>
            </IntroductionUl>

          <IntroHeading style={{width:"350px" }}>
          Common Laparoscopic Procedures
          </IntroHeading>
          <span style={{background:"#c81313", height:"1px", width:"100%"}}></span>
          <IntroductionUl>
              <IntroductionLi><FcDownRight/> Lap - Pyeloplasty</IntroductionLi>
              <IntroductionLi><FcDownRight/> Lap - Nephrectomy</IntroductionLi>
              <IntroductionLi><FcDownRight/> Lap - Ureterolithotomy</IntroductionLi>
              <IntroductionLi><FcDownRight/>  Lap - Renal Cyst Excision</IntroductionLi>
            </IntroductionUl>
        </Introduction>

        <OurConsultant>
          <div style={{display:"flex", flexDirection:" column", }}>
            <h1 style={{ marginBottom:"0px"}}>Our Consultant</h1>
            <span style={{background:"#c81313", height:"4px", width:"280px"}}></span>
          </div>
          <ConsultantDetails>
            <ConsultantAbout>
              <AboutDiv>
              <Doctorimg src="https://urostonedarbhanga.com/images/best_image_Dr.jpg" alt="Dr Manoj Kumar" />
              <span>Dr. Manoj Kumar, MS, Urologist</span>
              </AboutDiv>
              <IntroductionP>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem adipisci similique facilis sit repudiandae sequi? Accusantium saepe, pariatur sed fugit non porro sint cumque. Veritatis itaque quod repellat unde veniam!
                <br/>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque nam obcaecati minus quibusdam laborum blanditiis rerum fugit adipisci sapiente placeat qui possimus, explicabo aperiam vel magnam? In, minima animi. Sit.
                <br/>
              </IntroductionP>
              
            </ConsultantAbout>

            <ConsultantTiming>
              <h3>Consultation Timing ( परामर्श का समय )</h3>
                <CustomizedTables/>
            </ConsultantTiming>

          </ConsultantDetails>
        </OurConsultant>
      </UrologyDetails>
      <Footer />
    </UrologyWrapper>
  );
};

export default Services;


const OurConsultant = styled.div`
  margin-top: 2rem;
  background-color: white;  
  padding: 5px;
` 
const ConsultantDetails = styled.div`
  display:flex;
  align-items: flex-sart;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-top: 2rem;
  @media screen and (min-width:200px) and (max-width:992px){
    flex-direction:column;
  }

`
const ConsultantAbout = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 45%;
  @media screen and (min-width:200px) and (max-width:992px){
    width: 100%;
  }

`
const ConsultantTiming = styled.div`
width: 50%;
@media screen and (min-width:200px) and (max-width:992px){
  width: 100%;
}

`

const UrologyWrapper = styled.div`

`
const AboutDiv = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font: Italic;
    font-weight: 300;
    font-size: 18px;
    

`

const Doctorimg = styled.img`
    width: 100%;
    height: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
`

const Bannerdiv = styled.div`
position: relative;

`
const BannerImg = styled.img`
  width: 100vw;
  height: auto;
`

const BannerHeadingP = styled.p`
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const UrologyDetails = styled.div`
  background-color: #eee;
  padding-left:5%;
  padding-right: 5%;
  width: 100vw;
  padding-top: 3%;
  padding-bottom: 2.5rem;
  @media screen and (min-width:200px) and (max-width:600px){
    padding-left:2%;
    padding-right:2%;
  }

`

const Introduction = styled.div`
display: flex;
flex-direction: column;
background-color: white;
padding: 5px;

`
const IntroHeading = styled.span`
  background-color: #c81313 ;
  color: white;
  padding: 3px 10px;
  width: 150px;
  text-transform: uppercase;  
  font-weight: 600;
  letter-spacing: 1px;
`
const IntroductionP = styled.p`
  line-height: 2;
  font-weight: 400;
  font-size: 18px;
  opacity: 0.8;
`
const IntroductionUl = styled.ul`
  list-style-type: none;
`
const IntroductionLi = styled.li`
  font-size: 18px;
  line-height: 2;
  font-weight: 500;
`
