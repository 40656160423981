import React from 'react'
import Footer from '../footer/Footer'
import { styled } from 'styled-components'
import BookNow from '../pages/BookNow'
import CustomizedTables from '../services/CustomizedTables';
import { PiPhoneCallFill } from "react-icons/pi";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiTwotoneHome } from "react-icons/ai";

const Appointments = () => {
  return (
    <div>
    <Bannerdiv>
        <BannerImg src="https://urostonedarbhanga.com/images/homeeeee.gif" alt="banner"/>
        <BannerHeadingP> Appointment </BannerHeadingP>
      </Bannerdiv>

      <BookNow/>

      <ConsultantDetails>
            <ConsultantAbout>
              <IntroHeading>Secure Your Appointment</IntroHeading>
              <h3 style={{color:"#050572", fontWeight:"600", marginTop:"1rem",}}>Appointment Can be secured by Contacting Following Numbers: </h3>

              <ContactNumberDiv>
             
                  <Whatsappdivv>  WhatsApp Us On: <IoLogoWhatsapp  style={{color:"#c81313", fontSize:"20px", marginLeft:"1rem"}}/>
                  <LinkDivAA href="https://wa.me/7250500408"> +91 7250500408</LinkDivAA>
                  </Whatsappdivv>

                  <ParentCallDiv>  Call Us On: 
                    <PiPhoneCallFill style={{color:"#c81313", fontSize:"20px", marginLeft:"4rem" }}/>
                  <CallDiv>
                        <LinkDivAA href="tel:7250500408">+91 7250500408</LinkDivAA>,
                    </CallDiv>
                  </ParentCallDiv>

                  <ParentCallDiv>  Call Us On: 
                    <PiPhoneCallFill style={{color:"#c81313", fontSize:"20px", marginLeft:"4rem" }}/>
                  <CallDiv>
                        <LinkDivAA href="tel:9308930101">+91 9308930101</LinkDivAA>,
                    </CallDiv>
                  </ParentCallDiv>

                  <ParentCallDiv>  Call Us On: 
                    <PiPhoneCallFill style={{color:"#c81313", fontSize:"20px", marginLeft:"4rem" }}/>
                  <CallDiv>
                        <LinkDivAA href="tel:9262948578">+91 9262948578</LinkDivAA>
                    </CallDiv>
                  </ParentCallDiv>

                  <Addressdiv style={{marginTop:"20px"}}> Reach Us On: 
                    <AiTwotoneHome   style={{color:"#c81313", fontSize: "24px", marginLeft:"4rem" }} /> <span style={{marginLeft: "10px", fontSize:"20px", fontWeight:"500", color:"#050572"}}>
                    VIP Main Road, Allalpatti, Laheriasarai, Darbhanga, Bihar 846003
                    </span>
                  </Addressdiv>
                <span style={{color:"#c81313"}}>You can book your Appointment by filling the below form.</span>
              </ContactNumberDiv>

            </ConsultantAbout>

            <ConsultantTiming>
              <h3 style={{color:"#c81313", fontWeight:"700"}}>Consultation Timing ( परामर्श का समय )</h3>
                <CustomizedTables/>
            </ConsultantTiming>
           
      </ConsultantDetails>

     
    

        <Footer />
    </div>
  )
}

export default Appointments

const Addressdiv = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
margin-bottom: 1rem;
`;
const LinkDivAA = styled.a`
  text-decoration: none;
  font-size: 20px;
  margin-left: 10px;
  color:#050572;
font-weight: 600;
  &:hover{
    transition: transform .2s; /* Animation */
    font-size: 20px;
    color: #c81313;
    transition: ease-in-out .5sec;

  }
`;


const ParentCallDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
`

const CallDiv = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
`;

const Whatsappdivv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;

`;


const IntroHeading = styled.span`
  background-color: #c81313 ;
  color: white;
  padding: 7px 15px;
  width: 100%;
  text-transform: uppercase;  
  font-weight: 600;
  letter-spacing: 1px;
`


const Bannerdiv = styled.div`
position: relative;

`
const BannerImg = styled.img`
  width: 100vw;
  height: auto;
  max-height: 200px;
  loading:lazy;
`

const BannerHeadingP = styled.p`
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const AppointmentsContainer = styled.div`
width: 100vw; 
display:flex; 
flex-direction:column;

`

const ConsultantDetails = styled.div`
  background:url(https://urostonedarbhanga.com/images/appointments.gif);
  display:flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: 100vw;
  padding-left:4%;
  padding-right:4%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media screen and (min-width:200px) and (max-width:992px){
    flex-direction:column;
  }

`
const ConsultantAbout = styled.div`
  display:flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 45%;
  @media screen and (min-width:200px) and (max-width:992px){
    width: 100%;
  }

`
const ConsultantTiming = styled.div`
width: 50%;
@media screen and (min-width:200px) and (max-width:992px){
  width: 100%;
}

`

const IntroductionP = styled.p`
  line-height: 2;
  font-weight: 400;
  font-size: 18px;
  opacity: 0.8;
`
const ContactNumberDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  
`