import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Aos from 'aos';
import 'aos/dist/aos.css'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const BookNow = () => {
  const [fullName, setFullName] = useState("");
  const [date, setDate] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [village, setVillage] = useState("");
  const [block, setBlock] = useState("");
  const [district, setDistrict] = useState("");
  const [pin_code, setPin_code] = useState("");
  const [symptoms, setSymptoms] = useState("");

  const handleSubmit = (e) =>{
    e.preventDefault();
    console.log(fullName,date,contactNumber,email,age,village,block,district, pin_code, symptoms)
    setFullName(" ");
    setDate(" ");
    setContactNumber(" ");
    setEmail(" ");
    setAge(" ");
    setVillage(" ");
    setBlock(" ");
    setDistrict(" ");
    setPin_code(" ");
    setSymptoms(" ");

  }


  return (
    <div>
            <SectionHeadingg>
              Book Your Appointment Here
            </SectionHeadingg>
            <BookigContainer>

        <Imgdiv>
          <img src="https://urostonedarbhanga.com/images/book-img.svg" alt=""  />
        </Imgdiv>

{/* <BookAppointment>
<Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& > :not(style)': { m: 1 },
      }}
    >
      <TextField
        helperText="Please enter your name"
        id="demo-helper-text-aligned"
        label="Name"
      />
      <TextField
        helperText=" "
        id="demo-helper-text-aligned-no-helper"
        label="Name"
      />
    </Box>

</BookAppointment>
 */}

      <BookAppointment >
            <h4 style={{textAlign:"center", color:"#fff"}}>Book your Appointment</h4>
              <Form style={{display: "flex", flexDirection:" row", flexWrap:"wrap", justifyContent:"space-between"}} method="" action="" onSubmit={handleSubmit} >
                <Form.Group style={{minWidth:"275px", maxHeight:"100vw"}}>
                  <Form.Label style={{color:"#fff",fontWeight:"500", marginBottom:"2px", marginTop:"10px"}} >Full Name</Form.Label>
                  <Form.Control type="text" 
                                placeholder="Enter your full name" style={{width:"100%"}} name="fullName" 
                                onChange={(e) => setFullName(e.target.value)}
                                />
                </Form.Group>
                <Form.Group style={{minWidth:"275px"}}>
                  <Form.Label  style={{color:"#fff",fontWeight:"500", marginBottom:"2px", marginTop:"10px"}}>Appointment Date</Form.Label>
                  <Form.Control type="date" 
                                placeholder="Select Appointment Date" name="date" 
                                onChange={(e) => setDate(e.target.value)}
                                />
                </Form.Group>
                <Form.Group style={{minWidth:"275px"}}>
                  <Form.Label style={{color:"#fff",fontWeight:"500", marginBottom:"2px", marginTop:"10px"}}>Contact Number</Form.Label>
                  <Form.Control type="number" 
                                placeholder="Enter your Contact Number" name="contactNumber"
                                onChange={(e) => setContactNumber(e.target.value)}
                                />
                </Form.Group>
                <Form.Group style={{minWidth:"275px"}}>
                  <Form.Label style={{color:"#fff",fontWeight:"500", marginBottom:"2px", marginTop:"10px"}}> Email Address</Form.Label>
                  <Form.Control type="email" 
                                placeholder="Enter your your email address" name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                />
                </Form.Group>
                <Form.Group style={{minWidth:"275px"}}>
                  <Form.Label style={{color:"#fff",fontWeight:"500", marginBottom:"2px", marginTop:"10px"}}> Age</Form.Label>
                  <Form.Control type="number" placeholder="Enter your age"  name="age" 
                    onChange={(e) => setAge(e.target.value)}
                  />
                </Form.Group>
                <Form.Group style={{minWidth:"275px"}}>
                  <Form.Label style={{color:"#fff",fontWeight:"500", marginBottom:"2px", marginTop:"10px"}}>Village</Form.Label>
                  <Form.Control type="text" placeholder="Enter your village" name="village" 
                    onChange={(e) => setVillage(e.target.value)}
                  />
                </Form.Group>
                <Form.Group style={{minWidth:"275px"}}>
                  <Form.Label style={{color:"#fff",fontWeight:"500", marginBottom:"2px", marginTop:"10px"}}>Block</Form.Label>
                  <Form.Control type="text" placeholder="Enter your block" name="block" 
                    onChange={(e) => setBlock(e.target.value) }
                  />
                </Form.Group>
                <Form.Group style={{minWidth:"275px"}}>
                  <Form.Label style={{color:"#fff",fontWeight:"500", marginBottom:"2px", marginTop:"10px"}}>District</Form.Label>
                  <Form.Control type="text" placeholder="Enter your district" name="district" 
                    onChange={(e) => setDistrict(e.target.value)}
                  />
                </Form.Group>
                <Form.Group style={{minWidth:"275px"}}>
                  <Form.Label style={{color:"#fff",fontWeight:"500", marginBottom:"2px", marginTop:"10px"}}>Pin Code</Form.Label>
                  <Form.Control type="number" placeholder="Enter your Pin Code" name="pin_code" 
                    onChange={(e) => setPin_code(e.target.value)}
                  />
                </Form.Group>

                <Form.Group style={{minWidth:"100%"}}>
                  <Form.Label style={{color:"#fff",fontWeight:"500", marginBottom:"2px", marginTop:"10px"}}>Symptoms in Detail</Form.Label>
                  <Form.Control type="textarea" placeholder="Enter your Symptoms in Detail" name=" symptoms" 
                    onChange={(e) => setSymptoms(e.target.value)}
                  />
                </Form.Group>
                <Submitbutton type="submit">
                  Book Your Appointment 
                </Submitbutton>
              </Form>
        </BookAppointment>
      </BookigContainer>
    </div>
  )
}

export default BookNow


const Imgdiv = styled.div`
  min-width:300px;
  width: 30%;
  height: auto;
  @media screen and (min-width: 200px) and (max-width: 1000px){
    display: none;
    height: auto;
}
`

const Submitbutton = styled.button`
  align-self: center;
  margin-top: 1.5rem;
  font-size: 20px;
  font-weight: 500;
  color:#c81313;
  border: 1px solid #c81313;
  padding: 5px 15px 7px 15px;
  border-radius: 7px;
  text-decoration: none;
  background-color: white;
  &:hover{
      background-color: #c81313;
      color: white;
      transition: all 0.5s ease-in-out;
  }
`

const BookingImg = styled.img`
`

const BookigContainer = styled.div`
  display: flex;
  flex-direction: row; 
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
  margin-bottom: 30px;
  @media screen and (min-width: 200px) and (max-width: 600px){
    flex-direction: column;
}

`

const BookAppointment = styled.div`
  border-radius: 10px;
  width: 70vw;
  height: auto;
  background-color: rgb(18, 150,156);
  padding: 1rem;
  margin-top: 2rem;

  @media screen and (min-width: 200px) and (max-width: 1000px){
    width: 95vw; 
    margin-top: 0rem;
    height: auto;
    margin-bottom: 1rem;
  }
`
const SectionHeadingg = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: #c81313;
  text-align: center;
  margin-top: 1rem;
  @media screen and (min-width: 200px) and (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;