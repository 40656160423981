import React from "react";
import TopHeader from "../pages/TopHeader";
import Navbar from "../pages/Navbar";
import Footer from "../footer/Footer";
import { styled } from "styled-components";
import CT_SCAN from '../urostone_image/CT_Scan.jpeg';
import X_RAY from '../urostone_image/x-ray.jpeg';
import Pharmacy from '../urostone_image/medicine.jpeg';
import Pathology from '../urostone_image/pathology.jpeg';
import Lift from '../urostone_image/lift.jpeg';
import Waiting_area from '../urostone_image/Waiting_area.jpeg';


const Features = () => {
  return (
    <div>
      
      <Bannerdiv>
        <BannerImg src="https://urostonedarbhanga.com/images/facilities_page_banner.jpg" alt="banner"/>
        {/*         <BannerHeadingP> Facilities </BannerHeadingP> */}
      </Bannerdiv>
      <CareerContainer>
          <CareerHeading>Facilities Available at USRC </CareerHeading>
          <CareerContent>
           Our MD Dr. Manoj Kumar sir & management of Uro Stone Research Center has arranged every facilities required to 
           ease life of patients and his relative, while he is in Uro Stone Campus.  <Subhead6>  Facilities for Patients: </Subhead6>  Our management has arranges a world class expert Doctors and Nursing Staffs, 
           world class OT Theater fully equipped with latest surgical instrument and facilities.  
           A fully equipped ICU ward for intensive care of patients after operation. AC / Non-AC Rooms and Fully AC General Ward to take care of patients, until he gets discharge from here. 
           
           <Subhead6>  Facilities for Patients Guardians: </Subhead6>
          
           We have great Parking and Sitting space for the patients guardian in reception area. In house Pathology, Radiology and Pharmacy arrangement so that they don't have to rome around the city to find the medicine and get the lab reports. 
           Also  we have arranged canteen facilities also for patients as well kas his guardian. Here you can find every type of food for patients and your self.  
          </CareerContent>
          <EachCareerDiv>
            {/* <DetailsDiv > 
              <summery>Click me</summery>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga aliquam exercitationem molestiae vel, aut accusantium incidunt dolorum cumque ad alias aperiam labore repudiandae deleniti voluptatum ratione natus itaque! Voluptatem aliquam magni quisquam quam quas veritatis esse dicta suscipit distinctio, velit laboriosam cupiditate debitis, aspernatur tenetur modi provident dolor corrupti beatae.</p>
            </DetailsDiv> */}

          </EachCareerDiv>
      </CareerContainer>    
      <FeatureCardDiv>
        <CardOne>
          <TextParagraph>
          <Subhead6>  X-RAY </Subhead6>
          In Uro Stone Research Center have world class arrangements and a great team of radiology experts for accurate and most reliable radiology tests at very discounted price than market. Tests like MCU-RGU, IVP, CT Scan , Ultrasound test, MRI and all type of X-rays
          </TextParagraph>
          <ImageSec>
            <ShowImg src={X_RAY} alt="x-ray" />
          </ImageSec>
        </CardOne>

        <CardTwo>
        <ImageSec>
        <ShowImg src={CT_SCAN} alt="CT_Scan" />
        </ImageSec>
        <TextParagraph>
        <Subhead6>  Ultrasound & CT Scan </Subhead6>
        We have best doctors for Ultrasound and CT Scan. A tram of expert supporting staffs capable of operating 
        latest medical equipments in the market. We ensures that every patient gets their most accurate report to 
        get most accurate treatment. 
        </TextParagraph>

        </CardTwo>

        <CardThree>
          <TextParagraph>
          <Subhead6>  Pharmacy Store </Subhead6>
          Our hospital possesses a separate house of Pharmacy to ease life of patients and their guardians. Some time it is difficult to find right medicines for the patients. So, our hospital made arrangements of right medicine for the relative patients as advised by star Doctor Dr. Manoj Kumar sir.
          </TextParagraph>
          <ImageSec>
          <ShowImg src={Pharmacy} alt="Pharmacy" />
          </ImageSec>
        </CardThree>

        <CardFour>
        <ImageSec>
        <ShowImg src={Pathology} alt="Pathology" />
        </ImageSec>
          <TextParagraph>
          <Subhead6>  World Class Pathology </Subhead6>
          We have world class arrangements and a dedicated team of BMLT and MMLT staffs lead by Dr. R N Prasad for every type of pathological tests of top notch quality at very discounted rate than market.
          </TextParagraph>

        </CardFour>

        <CardFive>
          <TextParagraph>
          <Subhead6>  AC Waiting Area  </Subhead6>
            We ensure that every patient and their guardian have a comfortable and relaxed visit to Uro Stone Research Centre.
            We have fully AC with comfortable chair Waiting area capable to accommodate more than 100 persons. 
          </TextParagraph>
          <ImageSec>
          <ShowImg src={Waiting_area} alt="Waiting Area" />
          </ImageSec>
        </CardFive>

        <CardSix>
        <ImageSec>
        <ShowImg src={Lift} alt="Lift" />
        </ImageSec>

          <TextParagraph>
          <Subhead6>  Lift & Cafeteria   </Subhead6>
          Our MD Dr Manoj Kumar has ensured comfort and ease of patient and their guardian by arranging a very capable and advance cafeteria.
          Our cafeteria provides very testy and healthy food for patients and their guardian too. Our Cafeteria runs under guidance of a dietitian. We have ensured 
          very comfortable shifting of patient from one floor to another by installing lift. 
          </TextParagraph>
 
        </CardSix>
      </FeatureCardDiv>  
        <Footer />
    </div>
  );
};

export default Features;

const ShowImg = styled.img`
  height: 200px ;
  width: auto;
  @media screen and (min-width: 200px) and (max-width: 600px){
   width: 95%;   
  }
`


const ImageSec = styled.div`
  width: 45%;
  height: 200px;
  @media screen and (min-width: 200px) and (max-width: 600px){
    width: 95%;   
   }
`

const TextParagraph = styled.p`
width: 45%;
height: 200px;

margin:0;
@media screen and (min-width: 200px) and (max-width: 600px){
  width: 95%;  
  height:auto; 
 }
 @media screen and (min-width: 601px) and (max-width: 1000px){
  
  height:auto;
  
 }
`

const CardOne = styled.div`
display: flex;
flex-direction: row:   
gap:auto;
justify-content: space-between;
align-items: center;
width: 94%;
height: auto;
border-radius: 0px;
padding:10px;
// box-shadow: 0px 0px 2px 2px gold;
border-top: 5px solid gold;
border-left: 5px solid gold;

background: #eee;

@media screen and (min-width: 200px) and (max-width: 600px){
  width: 98%;
  flex-direction: column; 
 }
 @media screen and (min-width: 601px) and (max-width: 1000px){
  width: 98%;
  height:auto;
  
 }
`
const CardTwo = styled.div`
display: flex;
flex-direction: row:   
gap:auto;
justify-content: space-between;
align-items: center;
width: 94%;
height: auto;
padding:10px;
// box-shadow: 0px 0px 2px 2px green;
border-top: 5px solid green;
border-right: 5px solid green;
border-radius: 0px;
background: #eee;
@media screen and (min-width: 200px) and (max-width: 600px){
  width: 98%;
  flex-direction: column-reverse; 
 }
 @media screen and (min-width: 601px) and (max-width: 1000px){
  width: 98%;
  height:auto;
  
 }
`

const CardThree = styled.div`
display: flex;
flex-direction: row:   
gap:auto;
justify-content: space-between;
align-items: center;
width: 94%;
height: auto;
// box-shadow: 0px 0px 2px 2px gold;
border-top: 5px solid gold;
border-left: 5px solid gold;
padding:10px;
border-radius: 0px;
background: #eee;
@media screen and (min-width: 200px) and (max-width: 600px){
  width: 98%;
  flex-direction: column; 
 }
 @media screen and (min-width: 601px) and (max-width: 1000px){
  width: 98%;
  height:auto;
  
 }
`

const CardFour = styled.div`
display: flex;
flex-direction: row:   
gap:auto;
justify-content: space-between;
align-items: center;
width: 94%;
height: auto;
// box-shadow: 0px 0px 2px 2px green;
border-top: 5px solid green;
border-right: 5px solid green;
padding:10px;
border-radius: 0px;
background: #eee;
@media screen and (min-width: 200px) and (max-width: 600px){
  width: 98%;
  flex-direction: column-reverse; 
 }
 @media screen and (min-width: 601px) and (max-width: 1000px){
  width: 98%;
  height:auto;
  
 }
`
const CardFive = styled.div`
display: flex;
flex-direction: row:   
gap:auto;
justify-content: space-between;
align-items: center;
width: 94%;
height: auto;
// box-shadow: 0px 0px 2px 2px gold;
border-top: 5px solid gold;
border-left: 5px solid gold;
padding:10px;
border-radius: 0px;
background: #eee;
@media screen and (min-width: 200px) and (max-width: 600px){
  width: 98%;
  flex-direction: column; 
 }
 @media screen and (min-width: 601px) and (max-width: 1000px){
  width: 98%;
  height:auto;
  
 }
`
const CardSix = styled.div`
display: flex;
flex-direction: row:   
gap:auto;
justify-content: space-between;
align-items: center;
width: 94%;
height: auto;
// box-shadow: 0px 0px 2px 2px green;
border-top: 5px solid green;
border-right: 5px solid green;
border-bottom: 5px solid green;
padding:10px;
border-radius: 0px;
background: #eee;
@media screen and (min-width: 200px) and (max-width: 600px){
  width: 98%;
  flex-direction: column-reverse; 
 }
 @media screen and (min-width: 601px) and (max-width: 1000px){
  width: 98%;
  height:auto;
  
 }
`
const FeatureCardDiv = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction:column;
   height: auto;
   gap: 0px;
   padding-top: 10px;
   padding-bottom:10px;
   margin-bottom: 2rem;
`

const Subhead6 = styled.h6`
  font-size: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color:#050572;
`

const Bannerdiv = styled.div`
position: relative;

`
const BannerImg = styled.img`
  width: 100vw;
  height: auto;
  loading:lazy;
`

const BannerHeadingP = styled.p`
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const DetailsDiv = styled.details`

  color: #050572;
  font-size: 25px;
  margin-top: 2rem;
  padding: 8px 10px 10px 10px;
  
`
const CareerHeading = styled.h1`
font-size: 40px;
font-weight: 700;
color: #c81313;
text-transform: uppercase;
letter-spacing: 1px;
word-spacing: 20px;
margin-top: 1rem;
@media screen and (min-width:200px) and (max-width: 650px){
  font-size: 25px;
  font-weight: 700;
  letter-spacing: auto;
  word-spacing: auto;
}
`
const CareerContent= styled.div`
font-size: 22px;
font-weight: 500;
opacity: 0.9;
@media screen and (min-width:200px) and (max-width: 650px){
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
}
`

const EachCareerDiv = styled.div`

`

const CareerContainer = styled.div`
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 3rem;
  padding-top: 2rem;

`