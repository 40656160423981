import React ,{useState} from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { MenuList } from "./MenuList";
import "./Navbar.css";
import { BiMenuAltRight } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";


const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const menulists = MenuList.map(({url, title}, index) => {
    return (
      <li key={index}>
        <NavLink activeClassName="active" to={url}>{title}</NavLink>
      </li>
    )
  })

  const handleClicked = () => {
    setClicked(!clicked)
  }

  return (
      <NavigationDiv>
        <nav>
          <div className="logo">
            <img
                style={{ height: "50px" }}
                // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQr6n-8NSA2tu-udLZtmMPTa5cU9HBNxTd0adqNiL6s2iHdNvKxzk_BK1toeLqLbmTXcgV_HZ8mULQ&usqp=CAU&ec=48665698"
                src="https://lh3.googleusercontent.com/p/AF1QipNELp7Cdgyc4JSaSsbIydAD6-_nledvUrvJzpQY=s680-w680-h510"
                alt="logo"
              />
          </div>
          <div className="menu-icon"  onClick={handleClicked}>
            { clicked ? <FaTimes/> :<BiMenuAltRight />}
          </div>
          <ul className={clicked ? "menu-list" :" menu-list close"} onClick={handleClicked}  >{menulists}</ul>
         </nav>

      </NavigationDiv>
  );
};

export default Navbar;


const NavigationDiv = styled.div`
  z-index: 99;
  position: sticky;
  top: 0;
  transition-timing-function: ease-in-out ;
  transition: 0.25s;
  transform: translateY(0);

  background-color: #c81313;
  height:60px ;
  width: 100vw;
`

