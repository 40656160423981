import React, {useState} from "react";
import TopHeader from "../pages/TopHeader";
import Navbar from "../pages/Navbar";
import Footer from "../footer/Footer";
import { styled } from "styled-components";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const dod_doctors = [
  createData('Qualification', "MBBS, BUMS, BHMS etc from recognised University"),
  createData('Experience', "0 - 2 years"),
  createData('Location', "Darbhanga"),
  createData('Job Description', " He has to be present at hospital during duy hours. "),
  createData('Skills Required',  " He must have completed his degree with internship. "),
];

const BScNursing = [
  createData('Qualification', "BSc Nursing from recognised University "),
  createData('Experience', "0 - 2 years"),
  createData('Location', "Darbhanga"),
  createData('Job Description', " He has to be present at hospital during office hours and maintain the nursing works.   "),
  createData('Skills Required',  " He has to be aware all medical terminologies and his duties.    "),
];
const ANM = [
  createData('Qualification', "ANM from recognised University "),
  createData('Experience', "0 - 2 years"),
  createData('Location', "Darbhanga"),
  createData('Job Description', "He has to be present at hospital during office hours and maintain the nursing works.  "),
  createData('Skills Required',  " He has to be aware all medical terminologies and his duties.   "),
];
const GNM = [
  createData('Qualification', "GNM from recognised University "),
  createData('Experience', "0 - 2 years"),
  createData('Location', "Darbhanga"),
  createData('Job Description', "He has to be present at hospital during office hours and maintain the nursing works.  "),
  createData('Skills Required',  " He has to be aware all medical terminologies and his duties.   "),
];
const Management = [
  createData('Qualification', "MBA from recognised University "),
  createData('Experience', "0 - 2 years"),
  createData('Location', "Darbhanga"),
  createData('Job Description', " He has to look after daily works at hospital so that all the departments are working properly. He also has to resolve all the staffs related issues and making rosters. "),
  createData('Skills Required',  " He must have awesome managerial skill, good written and verbal communication skill and awesome people dealing skill.  "),
];
const Marketing = [
  createData('Qualification', "Graduation from recognised University "),
  createData('Experience', "0 - 2 years"),
  createData('Location', "Darbhanga"),
  createData('Job Description', " He has to develop good will of hospital in the market. He has to visit doctors in the market and represent our hospital and Dr Manoj Kumar sir. "),
  createData('Skills Required',  " He must have awesome managerial skill, good written and verbal communication skill and awesome people dealing skill. "),
];


const Career = () => {

  const [isOpen, setIsOpen] = useState(false)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Bannerdiv>
        <BannerImg src="https://urostonedarbhanga.com/images/Career_page_banner.png" alt="banner"/>
       {/*  <BannerHeadingP> Career </BannerHeadingP> */}
      </Bannerdiv>
        <CareerContainer>
          <CareerHeading>Career Opportunities at USRC </CareerHeading>
          <CareerContent>
            We are a team of more than 100 Persons. We are the Brand who provides a more sustainable career in wide variety of discipline. 
            People who join here are not just making their livelihood but build a sustainable career depending on their education and capabilities. 
            We always give opportunities to the bright minds who can justify the responsibilities given. 
            If you consider yourself capable for your post and want to grow in your career. <br></br>
            We are having currently openings on following positions. We welcome you to apply.
          </CareerContent>
          <EachCareerDiv>
          

            <DetailsDiv>
              <Detailsummary>DOD Doctors</Detailsummary>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{backgroundColor: "#050572", width: "100%"}}>
            <TableCell sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}>Job Title</TableCell>
            <TableCell align="center"  sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}> DOD Doctors</TableCell>
            <Button align="right" variant="contained" onClick={handleOpen}>Apply Now</Button>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {dod_doctors.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>

                </Table>
               </TableContainer>
            </DetailsDiv>

            <DetailsDiv>
              <Detailsummary>BSc Nursing </Detailsummary>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{backgroundColor: "#050572", width: "100%"}}>
            <TableCell sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}>Job Title</TableCell>
            <TableCell align="center"  sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}> BSc Nursing</TableCell>
            <Button align="right" variant="contained" onClick={handleOpen}>Apply Now</Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2" color='Green' fontWeight='600'>
                    Welcome to <br /> Uro Stone Research Centre.
                  </Typography>
                  <hr />
                  <img src="https://lh3.googleusercontent.com/p/AF1QipNELp7Cdgyc4JSaSsbIydAD6-_nledvUrvJzpQY=s680-w680-h510" alt="hospital logo" width='300' />
                  <hr />
                  <Typography variant="h6" fontSize='15px' fontWeight='600' sx={{mt:2}}>
                   Please mail us your updated resume.
                  </Typography>
                  <Button href='mailto:hr@urostonedarbhanga.com'  align="right"variant="contained" fontSize='13px' sx={{mt: 1}}> Email Your Resume</Button> 
                  <Button href='https://wa.me/8310885838' target='_blank' align="right" variant="contained" fontSize='13px' sx={{mt: 1}} >Whatsapp Your Resume</Button>
                  <Typography variant="h6" fontSize='15px' fontWeight='600' sx={{mt:2}}>Walk-in for interview at our address</Typography>
                  <hr />
                  <strong>Uro Stone Research Centre Pvt Ltd</strong> <br />
                  
                  <Typography variant="span" fontSize='14px'>
                    VIP  Road, Allalpatti Chowk,  <br/>
                    Allalpatti, Laheriyasaray, <br/>
                    Darbhanga, Bihar - 846003

                  </Typography>

                </Box>
              </Fade>
            </Modal>
          </TableRow>
        </TableHead>
        <TableBody>
          {BScNursing.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
                </Table>
               </TableContainer>            
              </DetailsDiv>

            <DetailsDiv>
              <Detailsummary> ANM </Detailsummary>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{backgroundColor: "#050572", width: "100%"}}>
            <TableCell sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}>Job Title</TableCell>
            <TableCell align="center"  sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}> ANM</TableCell>
            <Button align="right" variant="contained" onClick={handleOpen}>Apply Now</Button>
 

          </TableRow>
        </TableHead>
        <TableBody>
          {ANM.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>
            </TableRow>
          ))}
        </TableBody>
                </Table>
               </TableContainer>
            </DetailsDiv>

            <DetailsDiv>
              <Detailsummary>GNM </Detailsummary>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{backgroundColor: "#050572", width: "100%"}}>
            <TableCell sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}>Job Title</TableCell>
            <TableCell align="center"  sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}> GNM</TableCell>
            <Button align="right" variant="contained" onClick={handleOpen}>Apply Now</Button>


          </TableRow>
        </TableHead>
        <TableBody>
          {GNM.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
                </Table>
               </TableContainer>

            </DetailsDiv>

            <DetailsDiv>
              <Detailsummary>Management Staff </Detailsummary>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{backgroundColor: "#050572", width: "100%"}}>
            <TableCell sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}>Job Title</TableCell>
            <TableCell align="center"  sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}> Management Staff</TableCell>
            <Button align="right" variant="contained" onClick={handleOpen}>Apply Now</Button>


          </TableRow>
        </TableHead>

        <TableBody>
          {Management.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>

            </TableRow>
          ))}
        </TableBody>
                </Table>
               </TableContainer>
            </DetailsDiv>

            
            <DetailsDiv>
              <Detailsummary>Marketing Staff </Detailsummary>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{backgroundColor: "#050572", width: "100%"}}>
            <TableCell sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}>Job Title</TableCell>
            <TableCell align="center"  sx={{color:"#fff", fontWeight:"600", fontSize:"20px"}}> Marketing Staff</TableCell>
            <Button align="right" variant="contained" onClick={handleOpen}>Apply Now</Button>
           

          </TableRow>
        </TableHead>
        <TableBody>
          {Marketing.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
                </Table>
               </TableContainer>
            </DetailsDiv>


          </EachCareerDiv>
        </CareerContainer>
        
      <Footer />
     
    </div>
  );
};

export default Career;

const Detailsummary = styled.summary`
   background-color: #c81313;
   padding: 10px 15px;
   margin: 0;
   color: #fff;
   font-weight: 600;
`

const DetailsDiv = styled.details`
  border: 1px solid red;
  color: #050572;
  font-size: 25px;
  margin-top: 2rem;
padding: 0;
box-shadow: 0px 0px 2px 2px rgba(0,225,0,0.9)
  
`
const CareerHeading = styled.h1`
font-size: 40px;
font-weight: 700;
color: #c81313;
text-transform: uppercase;
letter-spacing: 1px;
word-spacing: 20px;
@media screen and (min-width:200px) and (max-width: 650px){
  font-size: 25px;
  font-weight: 700;
  letter-spacing: auto;
  word-spacing: auto;
}
`
const CareerContent= styled.div`
font-size: 22px;
font-weight: 500;
opacity: 0.9;
@media screen and (min-width:200px) and (max-width: 650px){
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
}
`

const EachCareerDiv = styled.div`

`

const CareerContainer = styled.div`
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 3rem;
  padding-top: 2rem;
`

const Bannerdiv = styled.div`
position: relative;

`
const BannerImg = styled.img`
  width: 100vw;
  height: auto;
  loading:lazy;
`

const BannerHeadingP = styled.p`
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`