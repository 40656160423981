import React from "react";
import TopHeader from "./TopHeader";
import Navbar from "./Navbar";
import Slider2 from "./Slider2";
import Footer from "../footer/Footer";
import ControlledCarousel from "./Slider";
import styled from "styled-components";
import HomeAbout from "./HomeAbout";
import Overlay from "./Overlay";
import Testimonials from "./Testimonials";
import PauseOnHover from "./PauseOnHover";


const Home = () => {
  return (
    <div>

      <Slider2 />
      <HomeAbout/>
     {/*  <Testimonials/> */}
      <PauseOnHover/>
{/* <Overlay/> */}
      <Footer />
    </div>
  );
};

export default Home;

