import React from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import './ButtonAnimation.css'


const TopHeader = () => {
  return (
   
      <TopHeaderdiv>
        <h1 style={{ color: "#c81313", margin: "0", fontWeight:"700" }}>URO STONE CLINIC</h1>
        <ButtonDiv>
          
              <HyperLink1 to="/appointment" className="animated-button">
                <span></span>
                <span></span>
                <span></span>
                <span></span>Appointments  
              </HyperLink1>
            
            <Styledbuttons style={{backgroundColor:"#c81313", color:"#fff"}}>
              <HyperLinks to="/emergency" style={{color:"#fff"}}>Emergency</HyperLinks>
            </Styledbuttons>
            
              <HyperLink to="/contact-us">Contact Us</HyperLink>
              
           
        </ButtonDiv>

        <TopHeaderImg
          style={{ height: "70px" }}
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQr6n-8NSA2tu-udLZtmMPTa5cU9HBNxTd0adqNiL6s2iHdNvKxzk_BK1toeLqLbmTXcgV_HZ8mULQ&usqp=CAU&ec=48665698"
          alt="logo"
        />
      </TopHeaderdiv>
   
  );
};

export default TopHeader;

const AppointmentStyledbutton = styled.button`
color: green;
background-color: white;
border: none;
font-size: 20px;
width: 150px;
border: 1px solid #c81313;
padding: 3px 15px;
border-radius: 3px;
cursor: pointer;
margin: 2px;
`


const TopHeaderdiv = styled.div`
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          
        @media and screen (nin-width: 200px) and (max-width: 600px){
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
`
const TopHeaderImg = styled.img`
  height:70px;
  
  @media screen and (max-width:800px) {
    display:none;
  }
  
`

const Styledbutton = styled.button`
  color: green;
  background-color: white;
  border: none;
  font-size: 20px;
  width: 150px;
  border: 1px solid #c81313;
  padding: 3px 15px;
  border-radius: 3px;
  cursor: pointer;
  margin: 2px;

  `
const Styledbuttons = styled.button`
  color: green;
  background-color: white;
  border: none;
  font-size: 20px;
  width: 150px;
  border: 1px solid #c81313;
  padding: 3px 15px;
  border-radius: 3px;
  cursor: pointer;
  margin: 2px;

  `
const ButtonDiv = styled.div`
display: flex;
align-items: flex-end;
flex-direction: row;

@media screen and (max-width:800px) {
  flex-direction: column;
}
`
const HyperLink1 = styled(Link)`
  text-decoration: none;
  
  margin-bottom: 1px;
  font-weight: 500;
padding-right: 5px;
color: green;
background-color: white;
border: none;
font-size: 20px;
width: 150px;
border: 1px solid #c81313;
padding: 3px 15px;
border-radius: 3px;
cursor: pointer;


  &:hover {
    color:#c81313;
  }
`
const HyperLink = styled(Link)`
  text-decoration: none;
  
  margin-bottom: 2px;
  font-weight: 500;

color: red;
background-color: white;
border: none;
font-size: 20px;
width: 150px;
border: 1px solid #c81313;
padding: 3px 15px;
border-radius: 3px;
cursor: pointer;


  &:hover {
    color:#c81313;
  }
`
const HyperLinks = styled(Link)`
  text-decoration: none;
  color: #050572;
  font-size:1.2rem;
  margin-bottom: 10px;
  font-weight: 500;
  &:hover {
    color:#fff;
  }
`