import logo from "./logo.svg";
import "./App.css";
import styled from "styled-components"; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./services/Services";
import Features from "./features/Features";
import Login from "./auth/Login";
import Register from "./auth/Register";
import Career from "./career/Career";
import AyushmanBharat from "./ayushmanBharat/AyushmanBharat";
import Navbar from "./pages/Navbar";
import Awards from "./awards/Awards";
import Blogs from "./blogs/Blogs";
import TopHeader from "./pages/TopHeader";
import ContactUs from "./miscellaneous/ContactUs";
import Appointments from "./miscellaneous/Appointments";
import Emergency from "./miscellaneous/Emergency";
import { BsWhatsapp } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { RiHospitalLine } from "react-icons/ri";
import PageNotFound from "./miscellaneous/PageNotFound";
import { ScrollRestoration } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <>
    <QuickLinks>
      <WhatsappButton href="https://wa.me/7250500408" target="_blank">
          <BsWhatsapp/>
      </WhatsappButton>
      <CallButton  href="tel:7250500408" target="_blank">
        <FiPhoneCall/>
      </CallButton>
      <EmailButton  href="mailto:hr@urostonedarbhanga.com" target="_blank">
        <img src="https://img.icons8.com/?size=512&id=qyRpAggnV0zH&format=png" alt="email" height="40" width="40"/>
          {/* <AiOutlineMail/> */}
      </EmailButton>
      <LocationButton href="https://www.google.com/maps/place/BEST+STONE+CLINIC+%7C+URO+STONE+RESEARCH+CENTRE+PVT.LTD,+DARBHANGA/@26.13827,85.9060733,17z/data=!3m1!4b1!4m6!3m5!1s0x39edb842c37a0a39:0xec9b684f511560b1!8m2!3d26.13827!4d85.908262!16s%2Fg%2F11c0pr_gpn?entry=ttu" target="_blank">
      <img src="https://img.icons8.com/?size=512&id=11934&format=png" alt="email" height="40" width="40"/>
          {/*  <RiHospitalLine/> */}
      </LocationButton>
    </QuickLinks>
     <NotificationDiv>
        <marquee>        Welcome to Uro Stone Research Center. This site is under maintenance. </marquee>
      </NotificationDiv>
      <BrowserRouter>
      <ScrollToTop/>
      <TopHeader/>
      <Navbar/> 
      <Routes>
      
        <Route index element={<Home/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/urology" element={<Services />} />
        <Route path="/features" element={<Features />} />
        <Route path="/awards" element={<Awards />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/career" element={<Career />} />
        <Route path="/ayushman" element={<AyushmanBharat />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/appointment" element={<Appointments />} />
        <Route path="/emergency" element={<Emergency />} />
        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
    </BrowserRouter>
    
    </>

  );
}

export default App;

const  QuickLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 160px;
  width:60px;
  position: fixed;
  top: 50%;
  right: 0px;
  z-index: 999;
`
const WhatsappButton = styled.a`
width: 50px;
height: 50px;
border: none;
margin-bottom: 1px;
background-color: green;
color:#fff;
font-size: 22px;
font-weight: 800;
display: flex;
align-items: center;
justify-content: center;
border: 1px solid #050578;
&:hover{
  background-color:#050572;
  color: #fff;
}
`
const CallButton = styled.a`
display: flex;
align-items: center;
justify-content: center;
width: 50px;
height: 50px;
border: none;
margin-bottom: 1px;
background-color: #fff;
color:#050578;
font-size: 22px;
font-weight: 800;
border: 1px solid #050578;
&:hover{
  background-color:#050572;
  color: #fff;
}
`
const EmailButton = styled.a`
display: flex;
align-items: center;
justify-content: center;
width: 50px;
height: 50px;
border: none;
margin-bottom: 1px;
background-color: #fff;
border: 1px solid #050578;
&:hover{
  background-color:#050572;
  color: #fff;
}
`
const LocationButton = styled.a`
display: flex;
align-items: center;
justify-content: center;
width: 50px;
height: 50px;
border: none;
margin-bottom: 1px;
background-color: #fff;
border: 1px solid #050578;
&:hover{
  background-color:#050572;
  color: #fff;
}
`

const NotificationDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 50px;
  background-color: green;
  color: white;
  font-size: 16px;
`