import React from 'react'
import Footer from '../footer/Footer'
import { styled } from 'styled-components'
import WhyChooseUs from '../pages/WhyChooseUs'
import { Link } from 'react-router-dom'

const Blogs = () => {
  return (
    <div>
      <Bannerdiv>
        <BannerImg src="https://urostonedarbhanga.com/images/blogs_banner_uro_stone.jpg" alt="banner"/>
        <BannerHeadingP> Blogs  Hi </BannerHeadingP>
      </Bannerdiv>
      <BlogContainer>
        <LeftDiv>

        </LeftDiv>
        <ContentDiv>
          <h2>All Blogs</h2>
          <BlogCard>
            <ImageDiv></ImageDiv>
            <BlogDetailDiv></BlogDetailDiv>
          </BlogCard>

          <BlogCard>
            <ImageDiv></ImageDiv>
            <BlogDetailDiv></BlogDetailDiv>
          </BlogCard>
        </ContentDiv>
       
      </BlogContainer>
      <Footer />
    </div>
  )
}

export default Blogs

const BlogCard = styled.div`
  height: 200px;
  margin-bottom: 1rem;
`

const ImageDiv = styled.div`

`
const BlogDetailDiv = styled.div`

`

const LeftDiv = styled.div`
  width: 20%;

  @media screen  and (max-width: 769px) {
   width: 100%;
  }
`

const ContentDiv = styled.div`
  Width: 75%;
  Color: Blue;

  @media screen  and (max-width: 769px) {
    width: 100%;
  }
  
`

const BlogContainer = styled.div`
  padding: 2.5rem 4%;
  display:flex;
  gap: 1em;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen  and (max-width: 769px) {
    flex-direction: column;
    gap:1rem;
  }
`

const Bannerdiv = styled.div`
position: relative;

`
const BannerImg = styled.img`
  width: 100vw;
  height: auto;
  loading:lazy;
`

const BannerHeadingP = styled.p`
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
