import React, { useRef, useState } from "react";
import { styled } from 'styled-components'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./homepagestyling.css";

const Testimonials = () => {
  return (
   <>
   <BannerImg src="https://urostonedarbhanga.com/images/blogs_banner_uro_stone.jpg" alt="banner"/>
    <BannerImg src="https://i0.wp.com/sevenseasmedia.org/wp-content/uploads/2017/05/widget-testimonial-background.jpg?ssl=1" alt="banner">
      </BannerImg>
       <TestimonialsContainer>
        <h2 style={{ marginBottom:"1rem", marginTop:"1rem", color: "#c81313"}}> Our Happy Patients </h2>
        <Swiper
        slidesPerView={2}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
        autoplay={{ 
            delay: 2500,
            disableOnInteraction: true,
        }}
        effect="slide" // Add the effect prop with the value "slide"
        loop={true} // Add the loop prop to enable continuous sliding
         >
        <SwiperSlide style={{height:"200px"}}>Slide 1</SwiperSlide>
        <SwiperSlide style={{height:"200px"}}>Slide 2</SwiperSlide>
        <SwiperSlide style={{height:"200px"}}>Slide 3</SwiperSlide>
        <SwiperSlide style={{height:"200px"}}>Slide 4</SwiperSlide>
        <SwiperSlide  style={{height:"200px"}}>Slide 5</SwiperSlide>
        <SwiperSlide  style={{height:"200px"}}>Slide 5</SwiperSlide>
        <SwiperSlide  style={{height:"200px"}}>Slide 5</SwiperSlide>
        <SwiperSlide style={{height:"200px"}}>Slide 6</SwiperSlide>
        <SwiperSlide style={{height:"200px"}}> Slide 7</SwiperSlide>
        <SwiperSlide style={{height:"200px"}}>Slide 8</SwiperSlide>
        <SwiperSlide style={{height:"200px"}}>Slide 9</SwiperSlide>
      </Swiper>
    </TestimonialsContainer>
   </>

  )
}

export default Testimonials

const TestimonialsContainer = styled.div`
   
    display: flex;
    flex-direction: column;
    padding-left:4%;
    padding-right:4%;
    padding-bottom: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

`

const BannerImg = styled.img`
  width: 100vw;
  height: auto;
  loading:lazy;
`