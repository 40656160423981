export const MenuList = [
    {
        title: "Home", 
        url:"/"
    },
    {
        title: "Urology",
        url:"/urology"
    },
    {
        title: "Facilities",
        url:"/features"
    },
    {
        title: "Aayushman Bharat",
        url:"/ayushman"
    },
    {
        title: "Awards & Recognition",
        url:"/awards"
    },
    {
        title: "Blogs",
        url:"/blogs"
    },
    {
        title: "Career",
        url:"/career "
    },
    /* {
        title: "Contact Us",
        url:"/contact-us "
    },
    {
        title: "Appointment",
        url:"/appointment "
    },
    {
        title: "Emergency",
        url:"/emergency"
    }, */
]