import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { BsChevronLeft } from "react-icons/bs";
import { BsChevronRight } from "react-icons/bs";
import './homepagestyling.css';

import { Link } from "react-router-dom";

const Slider2 = () => {
  // Create state variables for the index and image array
  const [index, setIndex] = useState(0);
  const [index1, setIndex1] = useState(0);
  const images = [
    "https://lh3.googleusercontent.com/p/AF1QipOmb_CdhvhmyBLywYSiTiExK89jYYprcpTK_-ST=s680-w680-h510",
    "https://lh3.googleusercontent.com/p/AF1QipNxFO2OH9wpexTTiAWn1YeMCqbk_sT9B6AGP8iN=s680-w680-h510",
    "https://lh3.googleusercontent.com/p/AF1QipO0iLlgDsml7pv8EPNQ2EL9Bf2GYMM7t7gdSR_s=s680-w680-h510",
    "https://lh3.googleusercontent.com/p/AF1QipOh9xux9gmHkWfrH8coeZZUAmLf-hReqaGOi2j1=s680-w680-h510",
    "https://urostonedarbhanga.com/images/Dr.Manoj_Kumar_Receiving_awaed_from_Governer.jpeg",
  ];

  const content2 = [
    "Best Urologist in the North Bihar",
    "Over 14000 Operation Done Successfully",
    "Over 70 OPD Daily",
    "Most Awarded Doctor in North Bihar",
    "Ex-Urologist at IGIMS, Patna",
   

  ]

  // Use useEffect to increment the index and update the image every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
      setIndex1((prevIndex) => (prevIndex + 1) % content2.length);
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  // Render the current image
  return (
    <CarouselContainer>
      <ImageDiv >
        <LeftButton onClick={() => setIndex((prevIndex) => (prevIndex - 1) % images.length) }> <BsChevronLeft/> </LeftButton>
        <SlidingImg
          src={images[index]}
          alt="Slider Image Uro Stone Clinic, Darbhanga"
        />
         <RightButton onClick={() => setIndex((prevIndex) => (prevIndex + 1) % images.length) }> <BsChevronRight/> </RightButton>
      </ImageDiv>
      <MaskLayer>
        <WelcomeText> {content2[index1]} </WelcomeText>
         <HyperLink to="/appointment" className="button" >Book Appointment Now</HyperLink>
      </MaskLayer>
    </CarouselContainer>
  );
};

export default Slider2;

const HyperLink = styled(Link)`
  text-decoration: none;
  color: #050572;


  font-weight: 500;

  background: #ddd;
  color: red;
  margin-top: 1em;
  padding: 10px 30px;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 30px;
  border: none;
  &:hover{
    background: red;
    color: white;
    transition:  all 0.5s ease-in-out;
  }
  @media screen and (min-width: 200px) and (max-width: 600px){
    font-size: 1rem;
    font-weight: 500;  
}
@media screen and (min-width: 601px) and (max-width: 900px){
  font-size: 1.2rem;
  font-weight: 500;  
}

`

const WelcomeText = styled.p`
  margin-bottom: 0;
  background: #fff;
  border-radius: 25px;
  padding: 10px 20px;
  color: blue;
  font-size: 2rem;
  font-weight: 600;
  @media screen and (min-width: 200px) and (max-width: 600px){
    font-size: 1rem;
    font-weight: 500;  
}
@media screen and (min-width: 601px) and (max-width: 900px){
  font-size: 1.2rem;
  font-weight: 500;  
}
`
const BookNowButtom = styled.button`
  background: #ddd;
  color: red;
  margin-top: 1em;
  padding: 10px 30px;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 30px;
  border: none;
  &:hover{
    background: red;
    color: white;
    transition:  all 0.5s ease-in-out;
  }
  @media screen and (min-width: 200px) and (max-width: 600px){
    font-size: 1rem;
    font-weight: 500;  
}
@media screen and (min-width: 601px) and (max-width: 900px){
  font-size: 1.2rem;
  font-weight: 500;  
}
`

const MaskLayer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000;
  opacity: .8;
  height: 100px;
  position: absolute;
  height: 80vh;
  width: 100vw;
  @media screen and (min-width: 200px) and (max-width: 600px){
      width: 100vw;
      max-height: 258.75px;
      height: 100%;
  }
`

const LeftButton = styled.button`
z-index: 11;
position: absolute;
top: 60%;
left:1%;
padding: 5px 10px;
font-size: 25px;
border: 1px solid #c81313;
opacity: 0.4;
&:hover{
  opacity:1;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 200px) and (max-width: 600px){
  top: 60%;
  left:1%;
  
}
`

const RightButton = styled.button`
position: absolute;
z-index: 11;
top: 60%;
right: 1%;
padding: 5px 10px;
font-size: 25px;
border: 1px solid #c80505;
opacity: 0.4;
&:hover{
  opacity:1;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 200px) and (max-width: 600px){
  top: 60%;
  right:1%;
  
}

`

const Imgdiv = styled.div`
  position: relative;
  width: 50%;
  height: auto;
  @media screen and (min-width: 200px) and (max-width: 600px){
    width: 95%;
    height: auto;
}
`

const Submitbutton = styled.button`
align-self: center;
margin-top: 1.5rem;
font-size: 20px;
font-weight: 500;
color:#c81313;
border: 1px solid #c81313;
padding: 5px 15px 7px 15px;
border-radius: 7px;
text-decoration: none;
background-color: white;
&:hover{
    background-color: #c81313;
    color: white;
    transition: all 0.5s ease-in-out;
}
`

const BookingImg = styled.img`
`

const BookigContainer = styled.div`
  display: flex;
  flex-direction: row; 
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
  @media screen and (min-width: 200px) and (max-width: 600px){
    flex-direction: column;
}

`


const ImageDiv = styled.div`
  height: 80vh;
  width: 100vw;
  @media screen and (min-width: 200px) and (max-width: 600px){
      width: 100vw;
      height: auto;
  }
`
const SlidingImg = styled.img`
  height: 80vh;
  width: 100vw;
  @media screen and (min-width: 200px) and (max-width: 600px){
      width: 100vw;
      max-height: 258.75px;
      min-height: 258.75px;
      height: 100%;
  }
`

const CarouselContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-contents: center;
  background-color: skyblue;
  padding-bottom: 5px;
   
`
const BookAppointment = styled.div`
  border-radius: 10px;
  width: 50vw;
  height: auto;
  background-color: rgb(18, 150,156);
  padding: 1rem;
  margin-top: 2rem;
  @media screen and (min-width: 200px) and (max-width: 600px){
    width: 90vw; 
    margin-top: 0rem;
  }
`
