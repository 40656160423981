import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IoLogoWhatsapp } from "react-icons/io";
import { BsFacebook, BsWhatsapp, BsFillSendFill } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { AiTwotoneHome } from "react-icons/ai";
import { PiPhoneCallFill } from "react-icons/pi";
import { TbWorldWww } from "react-icons/tb";
import './Footer.css';


const Footer = () => {
  return (
    <FooterWrapper style={{width:"100vw"}}>
      
      <AboveFooterDiv>
          <AboveFooterDivP>If, you have any query, suggestion, feel free to approach by clicking on Contact Us button.  </AboveFooterDivP>
          <ContactUsButton><HyperLink to="/contact-us" style={{ color:"blue"}} > <BsFillSendFill style={{ marginRight:"10px", color:"blue"}}/> Contact Us</HyperLink></ContactUsButton>
      </AboveFooterDiv>
      <Wrraper>
      
       <Div1>
        
        <img src="https://lh3.googleusercontent.com/p/AF1QipNELp7Cdgyc4JSaSsbIydAD6-_nledvUrvJzpQY=s680-w680-h510" alt="logo" style={{width:"100%", height:"auto", borderRadius:10}}/>
        <p> <b>Uro Stone Research Center</b> is a leading name in the field of <b>urology</b> and <b>Stone</b> related disease in Darbhanga and North Bihar. 
          I runs under guidance of well known urologist doctor <b>Dr. Manoj Kumar</b>.
         <b> Dr. Manoj Kumar</b> is a former senior resident doctor at <b>IGIMS, Patna</b>. 
        </p>
      {/*   <FollowusDiv>
          <span style={{color: "#c81313"}}>Follow us on:</span>
          <LinkDiv>
            <LinkDivA href="#"> <BsFacebook/> </LinkDivA>
            <LinkDivA href="#"> <AiFillTwitterCircle/> </LinkDivA>
            <LinkDivA href="#"> <AiFillInstagram/> </LinkDivA>
            <LinkDivA href="#"> <AiFillYoutube/> </LinkDivA>
          </LinkDiv>
        </FollowusDiv> */}
        <ButtonDiv>
            <Styledbutton >
              <HyperLinkk to="/appointment">Appointments</HyperLinkk>
            </Styledbutton>
            <Styledbutton>
              <HyperLinkk to="/emergency">Emergency</HyperLinkk>
            </Styledbutton>
            <Styledbutton>
              <HyperLinkk to="/contact-us">Contact Us</HyperLinkk>
              
            </Styledbutton>
        </ButtonDiv>
    </Div1>

    <Div3>
      <span style={{fontSize:"20px", fontWeight:"700",color:"#c81313", marginBottom:"20px"}}>Quick Links</span>
    <HyperLink to={"/"}>
            Home
          </HyperLink>

          <HyperLink  to={"/urology"}>
            Urology
          </HyperLink>

          <HyperLink to={"/features"}>
            Facilities
          </HyperLink>

          <HyperLink to={"/ayushman"}>
            Aayushman Bharat
          </HyperLink>

          <HyperLink to={"/awards"}>
            Awards & Recognition
          </HyperLink>

          <HyperLink to={"/blogs"}>
            Blogs
          </HyperLink>

          <HyperLink to={"/career"}>
            Career
          </HyperLink>


    </Div3>

    <Div2>
        <span style={{fontSize:"20px", fontWeight:"700",color:"#c81313"}}>Contact Us:</span>
    <Addressdiv style={{marginTop:"20px"}}>
      <AiTwotoneHome   style={{color:"#c81313", fontSize: "30px"}} /> <span style={{marginLeft: "10px"}}>
      VIP Main Road, Allalpatti, Laheriasarai, Darbhanga, Bihar 846003
      </span>
    </Addressdiv>

        <ContactNumberDiv>

          <Whatsappdivv> <IoLogoWhatsapp  style={{color:"#c81313", fontSize:"20px"}}/>
          <LinkDivAA href="https://wa.me/7250500408"> +91 7250500408</LinkDivAA>
          </Whatsappdivv>

           <ParentCallDiv>
           <PiPhoneCallFill style={{color:"#c81313", fontSize:"20px", }}/>
           <CallDiv>
                <LinkDivAA href="tel:7250500408">7250500408</LinkDivAA>,
                <LinkDivAA href="tel:9308930101">9308930101</LinkDivAA>,
                <LinkDivAA href="tel:9262948578">9262948578</LinkDivAA>
            </CallDiv>
           </ParentCallDiv>
           <Websitediv>
                <TbWorldWww  style={{color:"#c81313", fontSize:"20px"}} /><LinkDivAA href="https://urostonedarbhanga.com">https://urostonedarbhanga.com</LinkDivAA>
           </Websitediv>
        </ContactNumberDiv>
        <FollowusDiv>
          <span style={{color: "#c81313"}}>Follow us on:</span>
          <LinkDiv>
            <LinkDivA href="#"> <BsFacebook/> </LinkDivA>
            <LinkDivA href="#"> <AiFillTwitterCircle/> </LinkDivA>
            <LinkDivA href="#"> <AiFillInstagram/> </LinkDivA>
            <LinkDivA href="#"> <AiFillYoutube/> </LinkDivA>
          </LinkDiv>
        </FollowusDiv>

    </Div2>
      </Wrraper>

      <BottomDiv>
        Copyright © Uro Stone Research Center, Darbhanga | All right reserved
      </BottomDiv>
    </FooterWrapper>
  )

};

export default Footer;

const AboveFooterDiv = styled.div`
box-sizing: border-box;
  width: 100%;
  height:100px;
  background-color: #050572;
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding: .1% 7%;
  @media screen and (min-width: 200px) and (max-width: 500px){
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    padding-top: 1rem;
    height: 120px;
  }
`

const AboveFooterDivP = styled.p`
color: white;
font-weight: 600;
font-size: 20px;

@media screen and (min-width: 200px) and (max-width: 769px){
  font-weight: 400;
  font-size: 14px;
}

`

const ContactUsButton = styled.button`
  border: none;
  color: green;
  font-weight: 600;
  font-size: 16px;
  padding: 5px 13px;
  background-color: white;
  cursor: pointer;
  border-radius: 3px;
  min-width: 150px;
`

const HyperLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-size:1rem;
  margin-bottom: 10px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
    color:#c81313;
  }
`

const FooterWrapper = styled.div`
 background-color: #ffffff;
`

const  Wrraper = styled.div`
box-sizing: border-box;
margin-bottom: 0;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: space-between;
// background-color: #ffffff;
height: auto;
width: 100%;

padding: 2% 7%;
color: #fff;
@media screen and (min-width: 200px) and (max-width: 992px){
  flex-wrap: wrap;
}

background: linear-gradient(170deg, rgba(49, 57, 73, 0.8) 20%, rgba(49, 57, 73, 0.5) 20%, rgba(49, 57, 73, 0.5) 35%, rgba(41, 48, 61, 0.6) 35%, rgba(41, 48, 61, 0.8) 45%, rgba(31, 36, 46, 0.5) 45%, rgba(31, 36, 46, 0.8) 75%, rgba(49, 57, 73, 0.5) 75%), linear-gradient(45deg, rgba(20, 24, 31, 0.8) 0%, rgba(41, 48, 61, 0.8) 50%, rgba(82, 95, 122, 0.8) 50%, rgba(133, 146, 173, 0.8) 100%) #313949;

`

const Div1 = styled.div`
width: 33%;
height: 100%;
min-width:300px;

`
const Div3 = styled.div`
width: 33%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
color: black;
font-size: 14px;
padding-left: 5%;
padding-right: 5%;
min-width:300px;
@media screen and (min-width: 200px) and (max-width: 690px){
  padding-left: 0;
}
`

const Div2 = styled.div`
width: 33%;
height: 100%;
min-width:300px;
`

const ContactNumberDiv = styled.div`

`

const BottomDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: #444444;
color: white;
margin-top: 0;
@media screen and (min-width: 200px) and (max-width: 600px){
  font-size: 12px;
  align-items: center;
  padding-top:3px;
`

const FollowusDiv= styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
font-size: 16px;
`

const LinkDiv = styled.div`

`
const LinkDivA = styled.a`
  text-decoration: none;
  font-size: 30px;
  color: #fff;
  &:hover{
    transition: transform .2s; /* Animation */
    font-size: 33px;
    color: #c81313;
    transition: ease-in-out .5sec;
  }
`
const LinkDivAA = styled.a`
  text-decoration: none;
  font-size: 15px;
  margin-left: 10px;
  color:#fff;
  &:hover{
    transition: transform .2s; /* Animation */
    font-size: 16px;
    color: #c81313;
    transition: ease-in-out .5sec;
  }
`;
const Whatsappdivv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;

const ParentCallDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
`

const CallDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Addressdiv = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
margin-bottom: 1rem;
`;

const Websitediv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1rem;
`
const Styledbutton = styled.button`
  color: green;
  background-color: white;
  border: none;
  width: 130px;
  border: 1px solid #c81313;
  padding: 3px 5px;
  border-radius: 3px;
  cursor: pointer;
  margin: 2px;

  `
const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;


`
const HyperLinkk = styled(Link)`
  text-decoration: none;
  color: #050572;
  
  font-size:14px;
  margin-bottom: 10px;
  font-weight: 500;
  &:hover {
    color:#c81313;
  }
`