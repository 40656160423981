import React from 'react'
import Footer from '../footer/Footer'
import { styled } from 'styled-components'

const ContactUs = () => {
  return (
    <div>
      <Bannerdiv>
        <BannerImg src="https://urostonedarbhanga.com/images/contact-us.gif" alt="banner"/>
{/*         <BannerHeadingP> Contact Us </BannerHeadingP>
 */}     </Bannerdiv>
 <ContactFormH style={{color:" #c81313", textAlign: "center", marginTop: "1rem",}}>We are Available for You Round the Clock.</ContactFormH>
          <ContactUsContainer> 
            <ContactUsImage src="https://urostonedarbhanga.com/images/contact_us_image.jpg" alt="Contact-us-image"/>
            <ContactUsFormDiv>
              <ContactFormH >Kindly fill the Form for any Suggestions or Query </ContactFormH>
              <span style={{color:"#fff", marginLeft:"2rem", fontSize:"12px"}}>Your Contact Details will not be Disclosed to anyone. Required Fields are marked *</span>
              <Fieldinput type="text" name="name" id="name" placeholder="Full Name *" require/>
              
              <Fieldinput type="email" name="name" id="name" placeholder=" Email *" require/>
              
              <Fieldinput type="number" name="name" id="name" placeholder="Contact Number *" require/>

              <Fieldtextarea id="uro" name="uro" rows="4" cols="38" placeholder="Message..."> Message...</Fieldtextarea>
              <SubmitBUtton type="submit"> Submit </SubmitBUtton>
              <span style={{color:"#fff", marginLeft:"2rem", marginTop:"1rem"}}>Thank You for Filling the Form. We will reach to you shortly.</span>

            </ContactUsFormDiv>
         
          </ContactUsContainer>
            <div >
              <iframe
                  src="https://maps.google.com/maps?hl=en&amp;q=Uro Stone Research Center, Darbhanga &amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  width="101%" height="400" allowfullscreen="" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade">
              </iframe>
            </div>
          <Footer />
    </div>
  )
}

export default ContactUs;

const ContactFormH = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-left: 2rem;
  font-weight: 700;
  @media screen and (min-width: 200px) and (max-width: 500px){
  font-size:1.5rem;
  }

`

const SubmitBUtton = styled.button`
margin-left: 2.5rem;
margin-top: 1.5rem;
font-size: 20px;
font-weight: 500;
color:#050572;
border:none;
border-bottom: 1px solid #c81313;
padding: 5px 15px 7px 15px;

text-decoration: none;
background-color: white;
&:hover{
    background-color: #c81313;
    color: white;
    transition: all 0.5s ease-in-out;
}

`

const Fieldinput = styled.input`
  border-radius: 0px;
  padding: 5px 15px 8px 15px;
 
  width: 90%;
  margin-bottom: 1rem;
  border: none;

  margin-left: 2rem;
  opacity: 0.9;
  margin-top: 1rem;
`
const Fieldtextarea = styled.textarea`
border-radius: 0px;
padding: 1px 5px 2px 5px;
width: 90%;
margin-bottom: 1rem;
border: none;
margin-top: 1rem;
margin-left: 2rem;
opacity: 0.9;
`

const ContactUsFormDiv = styled.form`
  display:flex; 
  align-items:flex-start; 
  justifyContent: center; 
  flex-direction: column;
  width: 50%;
  margin-top: 4rem;
  background-color: #42acfb;
  padding-top: 3rem; 
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  @media screen and (min-width: 200px) and (max-width: 900px){
    width: 95%;
  margin-top:0;
  }

`

const ContactUsImage = styled.img`
  width: 45%;
  height: auto;
  @media screen and (min-width: 200px) and (max-width: 900px){
    width: 100%;
  
  }

`

const ContactUsContainer = styled.div`
width: 100vw; 
display:flex; 
align-items:flex-start; 
justifyContent: space-between; 
@media screen and (min-width: 200px) and (max-width: 900px){
  display: flex;
  flex-direction: column;
  align-items:center; 
}
`

const Bannerdiv = styled.div` 
position: relative;

`
const BannerImg = styled.img`
loading:lazy;
  width: 100vw;
  height:auto;
  max-height: 200px;
`

const BannerHeadingP = styled.p`
  font-size: 40px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`