import React from "react";
import TopHeader from "../pages/TopHeader";
import Navbar from "../pages/Navbar";
import Footer from "../footer/Footer";
import { styled } from "styled-components";

const AyushmanBharat = () => {
  return (
    <div>
      <Bannerdiv>
        <BannerImg src="https://lh3.googleusercontent.com/p/AF1QipO6poS4lofCu8xbq4xWJAV-tFQL6_7Cw3RHYTnM=s680-w680-h510" alt="banner"/>
{/*         <BannerHeadingP> Aayushman Bharat </BannerHeadingP> */}
      </Bannerdiv>
      <CareerContainer>
        <AyushDIV>
          <CareerHeading> PM-JAY @ USRC </CareerHeading>
          <CareerContent>
          Ayushman Bharat Pradhan Mantri Jan Arogya Yojana (PM-JAY) is a national public health insurance scheme of the Government of India that aims
           to provide free access to health insurance coverage for low income earners in the country.       <br/>    
          <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/1/1a/Ayushman_Bharat_logo.png/220px-Ayushman_Bharat_logo.png" alt="aaa"></img> 
          <br/>
          kindly visit our hospital and to get appointment for free operation under Ayushman Bharat Scheme. 
          <br/> For more information kindly call on the following number <a href="tel:7250500408">+91 7250500408</a>
          </CareerContent>
        </AyushDIV>

        <TpaDiv>

              <CareerHeading> TPA </CareerHeading>
              <CareerContent>
                Uro Stone Research Center have collaborated with 15+  TPA Partners. Some of them are:-
                <ol>
                  <li>SBI General Insurance Co. Ltd.</li>
                  <li>HDFC ERGO General Insurance Co. Ltd.</li>
                  <li>TATA AIG General Insurance Co. Ltd.</li>
                  <li>Med Vantage Insurance TPA Pvt. Ltd. </li>
                  <li>Paramount Health Services and Insurance TPA Pvt. Ltd.</li>
                  <li>Family Health Plan insurance TPA Ltd.</li>
                  <li>Vidal Health Insurance TPA Pvt. Ltd.</li>
                  <li>Good  Health Insurance TPA Pvt. Ltd.</li>
                  <li>Aditya Birla  Health Insurance TPA Pvt. Ltd.</li>
                  <li>Cholamandalam  MS General Insurance Co. Ltd.</li>
                  <li>Manipal Cigna Health Insurance Company Ltd. </li>
                  <li>Future Generali India Insurance Co. Ltd.</li>
                  <li>ICICI Lombard General Insurance Company Limited</li>
                  <li>Magma HDI General Insurance Company </li>
                  <li>Star Health & Allied Insurance CO. Ltd.</li>
                  <li>Reliance General Insurance Co. Ltd. </li>
                </ol>
              </CareerContent>
            {/* <DetailsDiv > 
              <summery>Click me</summery>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga aliquam exercitationem molestiae vel, aut accusantium incidunt dolorum cumque ad alias aperiam labore repudiandae deleniti voluptatum ratione natus itaque! Voluptatem aliquam magni quisquam quam quas veritatis esse dicta suscipit distinctio, velit laboriosam cupiditate debitis, aspernatur tenetur modi provident dolor corrupti beatae.</p>
            </DetailsDiv> */}

          </TpaDiv>
        </CareerContainer> 
      <Footer />
    </div>
  );
};

export default AyushmanBharat;

const AyushDIV = styled.div`
    width: 47%;
    background-color: #ddd;
    padding: 10px;
    border-radius:10px;
    box-shadow: 0px 0px 1.5px 1.5px gold;
    @media screen and (max-width: 900px){
      width: 95%;
      padding: 5px;
    }
    
`
const TpaDiv = styled.div`
    width: 47%;
    background-color: #ddd;
    padding: 10px;
    border-radius:10px;
    box-shadow: 0px 0px 1.5px 1.5px gold;
    @media screen and (max-width: 900px){
      width: 95%;
      padding: 5px;
    }
`

const Bannerdiv = styled.div`
position: relative;

`
const BannerImg = styled.img`
  width: 100vw;
  height: auto;
`

const BannerHeadingP = styled.p`
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const DetailsDiv = styled.details`
  border: 1px solid red;
  color: #050572;
  font-size: 25px;
  margin-top: 2rem;
  padding: 8px 10px 10px 10px;
  
`
const CareerHeading = styled.h1`
font-size: 40px;
font-weight: 700;
color: #c81313;
text-transform: uppercase;
letter-spacing: 1px;
word-spacing: 20px;
margin-top: 1rem;
@media screen and (min-width:200px) and (max-width: 650px){
  font-size: 25px;
  font-weight: 700;
  letter-spacing: auto;
  word-spacing: auto;
}
`
const CareerContent= styled.div`
font-size: 22px;
font-weight: 500;
opacity: 0.9;
@media screen and (min-width:200px) and (max-width: 650px){
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
}
`



const CareerContainer = styled.div`
  padding: 2.5rem 4%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: centre;
  @media screen and (max-width: 900px){
    flex-direction:column;
  }
  `