import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { FaQuoteLeft } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";


export default class PauseOnHover extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <>


      <TestimonialsContainer>
        <h2 style={{ marginBottom:"1rem", marginTop:"1rem", color: "#c81313"}}> Our Happy Patients </h2>

        <Slider {...settings} style={{padding:"10px", }}>
          <EachSlider>
              <InsideEachSlider>
                <TestimonialImg src="https://lh3.googleusercontent.com/a/AAcHTtdIrJ9DIIVs_XEDO3fuaD6RdDgpDuqd-mE4t-vZCqHq=w36-h36-p-rp-mo-br100" alt="Sumit Laha"/>
                <h6 style={{color:"#c81313"}}>Sumit Laha</h6>
                <StarDiv> ⭐⭐⭐⭐⭐ </StarDiv>
                <EachSliderP> <FaQuoteLeft style={{fontSize:"30px"}}/> <br/> <b>Dr. Manoj Kumar</b> is the best urologist in Bihar. I have recently visited due to my father(Kanti Kumar  Laha) enlarged prostate issue. Dr Manoj kumar is listening the problem of the patient very well and providing the best diagnosis. Before coming here I had also visited 4 urologist in Bihar and out of Bihar but Dr. Manoj kumar is provided best diagnosis among all. Thanks!
                    <HyperA href="https://g.co/kgs/BuJP7H" target="_blank" > See Review  </HyperA>
                </EachSliderP>
              </InsideEachSlider>  
         </EachSlider>

          <EachSlider>
                <InsideEachSlider>
                    <h6 style={{color:"#c81313"}}>Dilkhush Kumari</h6>
                    <TestimonialImg  src="https://lh3.googleusercontent.com/a/AAcHTteKmXT43qpbb5uIYmrUVU0Psq7PRUSb3gHxnxOV1y4=w36-h36-p-rp-mo-br100" alt="Dilkhush Kumari"/>
                    <StarDiv> ⭐⭐⭐⭐⭐ </StarDiv>
                    <EachSliderP>  <FaQuoteLeft style={{fontSize:"30px"}}/> <br/> Dr. Manoj Kumar sir is very fare surgeon for stone Operation.
                        I suggest people if you have any stone related problem then definitely reached to uro stone all staff is vary cooperative.
                        Uro stone research senter is better than other hospital 🏥  thanku sir & my lovely sweet hospital  Uro stone research senter allalpatti darbhanga thank you forever 👌👌👌❤️🏥 
                        <HyperA href="https://goo.gl/maps/pujfwtey1hcaA4fq7" target="_blank" > See Review  </HyperA>
                        </EachSliderP>
                </InsideEachSlider>          
            </EachSlider>

          <EachSlider>
                <InsideEachSlider>
                <h6 style={{color:"#c81313"}}>Sunil Sharma</h6>
                    <TestimonialImg src="https://lh3.googleusercontent.com/a-/AD_cMMQwHsNKydJBlYroabJsVVCxzO9h-HjmKuaFJ2AsUEUBYg=w36-h36-p-rp-mo-br100" alt="Sunil Sharma"/>
                    <StarDiv> ⭐⭐⭐⭐ <AiOutlineStar style={{fontSize:"20px"}}/> </StarDiv>
                    <EachSliderP> <FaQuoteLeft style={{fontSize:"30px"}}/> <br/>
                    Uro Stone research centre is better than orther hospital.
                        dr. Manoj Kumar ⭐⭐⭐⭐⭐
                        And hospital staff Ajay ji is very Sweet guy
                        Very good Service provide by uro stone research centre.dbg
                        Thanks to PM narendra modi ji & Dr. Manoj kumar urologist for providing Aam public Aayushman Bharat yojna.
                        Love you all Staff of kidney Care 🥰❤️♥️
                        <HyperA href="https://goo.gl/maps/mcCjCnXEpPE7mXar8" target="_blank" > See Review  </HyperA>
                        </EachSliderP>
                </InsideEachSlider>  
          </EachSlider>

          <EachSlider>
                <InsideEachSlider>
                    <h6 style={{color:"#c81313"}}>Prity Yadav</h6>
                    <TestimonialImg src ="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-rdfBx_rZg33dq4_kkBtVKkRJUWA_GuVID-h5UrKGVHjrLt5hpTXagFAgyN3jnbHLYyY&usqp=CAU" alt="priti Yadav"/>
                    <StarDiv> ⭐⭐⭐⭐⭐</StarDiv>
                    <EachSliderP> <FaQuoteLeft style={{fontSize:"30px"}}/> <br/>(Translated by Google) Ham aapne papa Shivnarayan Yadav kay elaj karbo lay aaelai chhaliyai
                        Bad nik vyavastha chhaal aato kay
                        Dr. Sahab tay hamar jan bacha delka
                        a lot of respect
                            <br/>
                        (Original)
                        Ham aapne papa shivnarayan Yadav kay elaj karbo lay aaelai chhaliyai
                        Bad nik vyavastha chhaal aato kay
                        Dr. Sahb tay hamar jan bacha delka
                        Bahut bahut aabhar
                        <HyperA href="https://goo.gl/maps/dza9GffyM6buaqsQ9" target="_blank" > See Review  </HyperA>
                    </EachSliderP>
                </InsideEachSlider>
          </EachSlider>

          <EachSlider>
                <InsideEachSlider>
                    <h6 style={{color:"#c81313"}}>Misha Kumari</h6>
                    <TestimonialImg src="https://media.licdn.com/dms/image/D4D03AQFMzH2J73SV8g/profile-displayphoto-shrink_800_800/0/1665756525483?e=2147483647&v=beta&t=9IYAkxC4NZnysdUicu5oCZezGnIoAUDSPc2k1N5ocUM" alt="Misha kumari" />
                    <StarDiv> ⭐⭐⭐⭐⭐</StarDiv>
                    <EachSliderP><FaQuoteLeft style={{fontSize:"30px"}}/> <br/>Uro stone research centre is best hospital and Dr is just like second God as I experienced during treatment of my mother . Dr is very kind and generous , they provide new lyf to hopeless patients. They perform all surgery in fine way like turp , turbt ,cbd stones ,renal stones and so ... Management of this hospital is really appreciable . All staff is very cooperative. If patients is poor and helpless then Dr do their treatment in free as I saw. Even many times they refund fees of poor man . In reality this hospital is best among darbhanga and Patna . Dr diagnosis is very good and prognosis of patients is really very good.
                    
                    <HyperA href="https://goo.gl/maps/NYJfBLS554Tj1dkH9" target="_blank" > See Review  </HyperA>
                    </EachSliderP>
                </InsideEachSlider>
          </EachSlider>


          <EachSlider>
                <InsideEachSlider>
                    <h6 style={{color:"#c81313"}}>Abhinandan Kumar Singh</h6>
                    <TestimonialImg src="https://lh3.googleusercontent.com/a-/AD_cMMS96gEiwaPv3uZ4VDb742bDxTlXQPWpzTDBHAgRBpcxHjw=w60-h60-p-rp-mo-br100" alt="Abhinandan Kumar singh"/>
                    <StarDiv> ⭐⭐⭐⭐⭐</StarDiv>
                    <EachSliderP> <FaQuoteLeft style={{fontSize:"30px"}}/> <br/>Best hospital in Darbhanga. Dr is really very good . They provide best treatment in urology department. Facilities are different from all hospital. Very neat and clean hospital.
                    <HyperA href="  https://goo.gl/maps/LPpzKoo7KzNbGbQb7" target="_blank" > See Review  </HyperA>
                  
                    </EachSliderP>
                </InsideEachSlider>
          </EachSlider>
       
        </Slider>
        <ReviewButton href="https://g.page/r/CbFgFVFPaJvsEB0/review" target="_blank"> Kindly Review Us</ReviewButton>
      </TestimonialsContainer>
      </>

      
    );
  }
}

const BannerImg = styled.img`
  width: 100vw;
  height: auto;
  loading:lazy;
`

const StarDiv = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-top: 1rem;
`
const ReviewButton = styled.a`
    align-self: center;
    margin-top: 2.5rem;
    font-size: 20px;
    font-weight: 500;
    color:#c81313;
    border: 1px solid #c81313;
    padding: 5px 15px 7px 15px;
    border-radius: 3px;
    text-decoration: none;
    background-color: white;
    &:hover{
        background-color: #c81313;
        color: white;
        transition: all 0.5s ease-in-out;
    }
`

const HyperA = styled.a`
    text-decoration: none;
    color: #050572;
    font-weight: 500;
    margin-left:10px;
    &:hover{
        color: #c81313;
    }
`
const TestimonialImg = styled.img`
    height: 75px;
    width: 75px;
    position: absolute;
    top:0%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    border-radius:50%;
`

const EachSliderP = styled.p`
`
const TestimonialsContainer = styled.div`
    background-image: url("https://i0.wp.com/sevenseasmedia.org/wp-content/uploads/2017/05/widget-testimonial-background.jpg?ssl=1");
    background-color: #eeffdd;
    display: flex;
    flex-direction: column;
    padding-left:4%;
    padding-right:4%;
    padding-bottom: 2rem;
    width:100vw;


`

const EachSlider = styled.div`
    border-radius:5%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-right: 10px;
    margin-top: 3rem;
    z-index: 99;
`

const InsideEachSlider = styled.div`
    background-color: #fff;
    position: relative;
    padding: 5px;
`